import * as actions from '../actions/organisation.actions';

const carrierGetUrlReducer = function (state = {}, action) {
	switch (action.type) {
		case actions.GET_FILE_URL:
			if (action.payload) {
				return {
					...action.payload
				};
			}
			break;
		case actions.GET_FILE_URL_ERROR:
			if (action.payload) {
				return {
					...action.payload
				};
			}
			break;
		default:
			return state;
	}
};

export default carrierGetUrlReducer;
