import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
	name: 'layout',
	initialState: {
		"layout":'public',
		"header":true,
		"sidebar":false,
	},
	reducers: {
		getLayout: (state, action) => {
			state.layout = action.payload.layout;
		},
	}
});

export const { getLayout } = slice.actions;

export default slice.reducer;
