import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import CardMedia from '@material-ui/core/CardMedia';
// import Hidden from '@material-ui/core/Hidden';

import Dialog from '@material-ui/core/Dialog';

import RoomIcon from '@material-ui/icons/Room';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import MailIcon from '@material-ui/icons/Mail';

import * as Actions from '../store/contactSlice/actions';
const mapImg = require('../../../images/map.png')
const useStyles = makeStyles(theme => ({
	container: {
		maxWidth: '100%',
		fontFamily: 'Lato',
		background: '#2C7FF8',
		padding: 50
	},
	title: {
		color: 'white',
		fontSize: 25,
		fontWeight: 'bold',
		marginLeft: 10,
		marginTop: 10
	},
	main: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' },
	grid: { width: '90%', height: '80%', paddingTop: 50 },
	form: {
		display: 'flex',
		flexDirection: 'row',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column'
		}
	},
	input: { margin: 15, flex: 1 },
	button: {
		fontFamily: 'Lato',
		fontSize: 13,
		color: 'white',
		background: '#217af8',
		textTransform: 'none',
		marginTop: 40,
		borderRadius: 10,
		borderWidth: 0,
		padding: 12,
		width: '40%',
		'&:hover': {
			background: '#217af8',
			color: 'white !important',
			borderWidth: 0
		}
	},
	contact: {
		height: '100%',
		padding: 30,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
		[theme.breakpoints.only('md')]: {
			flexDirection: 'row'
		},
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			marginTop: 15,
			marginBottom: 15
		}
	},
	text: {
		fontSize: 18,
		marginLeft: 10
	},
	subDiv: {
		display: 'flex',
		flex: 1,
		alignItems: 'center'
	},
	icon: { color: 'white', fontSize: 35 },
	contactBgContainer: {
		width: "100%",
		height: "100%",
		position: "absolute",
		bottom: 0,
		top: 0,
		left: 0,
		backgroundColor: "#3682f6",
		opacity: "0.1"
	}
}));

function Contact(props) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [name, setName] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [phonenumber, setPhone] = React.useState('');

	const [companyName, setCompany] = React.useState('');
	const [description, setMessage] = React.useState('');

	const [loader, setLoader] = React.useState(false);
	const [response, setResponse] = React.useState(null);
	const [open, setOpen] = React.useState(false);

	const handleContact = async () => {
		const payload = { name, phonenumber, email, companyName, description };

		setLoader(true);
		setResponse(null);

		const res = await dispatch(Actions.ContactEmail(payload));

		setResponse(res);
		setLoader(false);
		setOpen(true);

		setName('');
		setPhone('');
		setEmail('');
		setCompany('');
		setMessage('');
	};

	return (
		<Container className={classes.container} style={{position: 'relative'}}>
			<div className={classes.contactBgContainer} style={{background: `url(${mapImg})`}}></div>
			<div className={classes.title + " ffb-primary"}>Contact Us</div>

			<div className={classes.main}>
				<Grid container spacing={3} className={classes.grid + " ffsb-primary"}>
					<Grid item xs={12} lg={7} style={{ padding: 30, background: '#fff', zIndex: '999' }}>
						<h1>Send us a message</h1>

						<div className={classes.form + " ffr-primary"}>
							<TextField
								className={classes.input}
								label="Your Name"
								value={name}
								inputProps={{ style: { fontSize: 15 } }}
								InputLabelProps={{ style: { fontSize: 16 } }}
								onChange={e => setName(e.target.value)}
							/>

							<TextField
								className={classes.input}
								label="Email Address"
								value={email}
								inputProps={{ style: { fontSize: 15 } }}
								InputLabelProps={{ style: { fontSize: 16 } }}
								onChange={e => setEmail(e.target.value)}
							/>
						</div>

						<div className={classes.form + " ffr-primary"}>
							<TextField
								className={classes.input}
								label="Mobile Number"
								value={phonenumber}
								inputProps={{ style: { fontSize: 15 } }}
								InputLabelProps={{ style: { fontSize: 16 } }}
								onChange={e => setPhone(e.target.value)}
							/>
							<TextField
								className={classes.input}
								label="Company Name"
								value={companyName}
								inputProps={{ style: { fontSize: 15 } }}
								InputLabelProps={{ style: { fontSize: 16 } }}
								onChange={e => setCompany(e.target.value)}
							/>
						</div>

						<div className={classes.form + " ffr-primary"}>
							<TextField
								className={classes.input}
								label="Your Message"
								value={description}
								inputProps={{ style: { fontSize: 15 } }}
								InputLabelProps={{ style: { fontSize: 16 } }}
								onChange={e => setMessage(e.target.value)}
							/>
						</div>

						<center>
							{loader ? (
								<div style={{ marginTop: 30, fontSize: 20, color: '#0062FF'}} className={"ffsb-secondary"} >
									Sending Details ... Please Wait ...{' '}
								</div>
							) : null}

							<Button variant="outlined" className={classes.button + " ffsb-secondary"} onClick={() => handleContact()}>
								Contact Us
							</Button>
						</center>
					</Grid>

					<Grid item xs={12} lg={5} style={{ background: 'black', color: '#fff', zIndex: '999' }} className={"ffb-primary"}>
						<div className={classes.contact}>
							<div className={classes.subDiv}>
								<RoomIcon className={classes.icon} />
								<div className={classes.text}>
									Plot no -62/3, Rd no- 35, Jubilee Hills, Hyderabad, Telangana, 500033
								</div>
							</div>

							<div className={classes.subDiv}>
								<PhoneAndroidIcon className={classes.icon} />
								<div className={classes.text}>+91 9703709049 | 9703860158</div>
							</div>

							<div className={classes.subDiv}>
								<MailIcon className={classes.icon} />
								<div className={classes.text}>contact@simplelr.com</div>
							</div>
						</div>
					</Grid>
				</Grid>
			</div>

			<Dialog open={open} onClose={() => setOpen(false)}>
				<h2 style={{ padding: 30 }} className={"ffsb-primary"}>
					{response && response.status === 200
						? 'Success! Please check confirmation mail sent to your mail id'
						: 'Failed! Please try again later'}
				</h2>
			</Dialog>
		</Container>
	);
}

export default Contact;
