import React, { useContext } from 'react';
import { renderRoutes } from 'react-router-config';
import { makeStyles } from '@material-ui/core/styles';

import { MixpanelConsumer } from 'react-mixpanel';

import AppContext from 'app/AppContext';
import Header from './Header';
import Footer from './Footer';

import Home from '../../main/pages/components/Home';
import AboutUs from '../../main/pages/components/AboutUs';
import Support from '../../main/pages/components/Support';
import Contact from '../../main/pages/components/Contact';

const useStyles = makeStyles(theme => ({
	subDiv: {
		height: '100%',
		background: 'white',
		'overflow-y': 'auto',
		'scroll-behavior': 'smooth',
		'&::-webkit-scrollbar': {
			width: 0
		}
	}
}));

function PublicLayout(props) {
	const classes = useStyles();

	const appContext = useContext(AppContext);
	const { routes } = appContext;

	return (
		<div className="flex flex-1 flex-col overflow-hidden relative">
			<MixpanelConsumer>{mixpanel => <Header mixpanel={mixpanel} />}</MixpanelConsumer>

			<div className={classes.subDiv}>
				<a name="home">
					<Home />
				</a>
				<a name="about-us">
					<AboutUs />
				</a>
				<a name="support">
					<Support />
				</a>
				<a name="contact">
					<Contact />
				</a>

				<Footer />
			</div>
		</div>
	);
}

export default React.memo(PublicLayout);
