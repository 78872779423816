import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
	name: 'pages',
	initialState: {},
	reducers: {
		contact: (state, action) => {
			state.contact = action.payload;
		}
	}
});

export const { contact } = slice.actions;

export default slice.reducer;
