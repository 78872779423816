// import React from 'react';
// import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/utils';

// Private Routes
import WelcomeConfig from 'app/main/welcome/WelcomeConfig';
// import ProfileConfig from 'app/main/profile/ProfileConfig';
// import TripsConfig from 'app/main/trips/TripsConfig';
//import TripsConfig from 'app/main/tripsNew/TripsConfig';
import OrgKycConfig from 'app/main/OrganizationKyc/OrganizationKycConfig';
import KycConfig from 'app/main/Kyc/KycConfig';
import ProfilePageConfig from 'app/main/profilePage/ProfilePageConfig';

import Home from 'app/main/pages/components/Home';

import SettingsConfig from 'app/main/settings/SettingsConfig';
//import CustomersConfig from 'app/main/customers/CustomersConfig';
//import SuppliersrsConfig from 'app/main/suppliers/SuppliersConfig';
import ReportsConfig from 'app/main/reports/ReportsConfig';
// import AccountsConfig from 'app/main/accounts/AccountsConfig';
//import DirectoriesConfig from 'app/main/directories/DirectoriesConfig';

const routeConfigs = [
	WelcomeConfig,
	//TripsConfig,
	SettingsConfig,
	KycConfig,
	ProfilePageConfig,
	OrgKycConfig,
	//CustomersConfig,
	//SuppliersrsConfig,
	ReportsConfig,
	// AccountsConfig,
	//DirectoriesConfig
];

const routes = [
	...FuseUtils.generateRoutesFromConfigs(routeConfigs),
	{
		path: '/',
		component: Home,
		isPrivate: false
	}
];

export default routes;
