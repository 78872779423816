import Settings from './Settings';

const SettingsConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/users',
			component: Settings,
			isPrivate: true
		}
	]
};

export default SettingsConfig;
