import React, { useState, useEffect } from 'react';
import {
	Grid,
	// Button,
	Dialog,
	DialogActions,
	// DialogTitle,
	TextField,
	// Divider,
	MenuItem
	// IconButton
} from '@material-ui/core';
// import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
// import PhotoCamera from '@material-ui/icons/PhotoCamera';
// import Upload from './upload.js';
// import ProgressBar from './../Kyc/FileLoadingProgress';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
// import * as actions from './store/actions';
// import * as actions1 from './../Kyc/store/actions';
import * as actions from './redux/userApi.js';

const roles = [
	{
		value: 'admin',
		label: 'ADMIN'
	},
	{
		value: 'employee',
		label: 'EMPLOYEE'
	}
];
const useStyles = makeStyles(theme => ({
	root: {
			padding: "20px 30px"
		},
	input: {
		display: 'none'
	},
	imageupload: {
		display: 'block',
		marginTop: '26px',
		textAlign: '-webkit-center'
	},
	textField: {
		marginTop: "10px"
	},
	btn: {
		paddingRight: "0px"
	}
}));
function UserEdit(props) {
	// console.log(props, 'propsedit');
	const { items, open, title } = props;
	const [values, setValues] = useState(items);
	const classes = useStyles();
	// const [images, setImages] = useState('');
	const dispatch = useDispatch();
	const [imagedit, setImageEdit] = useState();
	const {userUpload} = useSelector(state => state.usersStore);
	const image = userUpload && userUpload.data ? userUpload.data[0] : null;
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setValues(items);
	}, [items]);
	useEffect(() => {
		if (items && items.profilePic && items.profilePic !== '' && image === null) {
			setImageEdit(items.profilePic);
		} else {
			setImageEdit(image);
		}
		setLoading(false);
	}, [image, items]);
	useEffect(() => {
		if (image && image !== '' && image !== undefined) {
			// setImages(image);
			setValues({
				...values,
				profilePic: image
			});
		}
	}, [userUpload, image]);

	const uploadFileHandler = (event, docType) => {
		const file = event.target.files[0];
		// console.log(file, 'file');
		const id = 'phone';
		const formData = new FormData();
		formData.append('documents', file);
		setLoading(true);
		dispatch(actions.uploadUserApi(formData, id, docType));
	};

	const handlechange = e => {
		const { name, value } = e.target;
		setValues({
			...values,
			[name]: value
		});
	};
	// const handleClearImage = () => {
	// 	// setImageEdit('')
	// 	dispatch(actions.imageResetApi());
	// };
	const handleCancel = (event) => {
		event.preventDefault();
		props.handleClose(true)
	}

	const handleSubmit = event => {
		event.preventDefault();
		if (values.role && values.role.length > 0 && values.role === items.role) {
			delete values.role;
		}
		dispatch(actions.updateUserApi(values));
		props.handleClose();
	};

	return (
		<>
			<Dialog
				maxWidth="sm"
				open={open}
				BackdropProps={{
					classes: {
						root: classes.backDrop
					}
				}}
				aria-labelledby="form-dialog-title"
			>
				{/* <DialogTitle id="form-dialog-title">{title}</DialogTitle>
				<Divider /> */}
				<p style={{color: "#000000DE",padding: "14px 20px"}}>{title}</p>
				<hr
					style={{
						height: 0.5,
						borderColor: '#ccc'
					}}
				/>
				<form className={classes.root}>
					<Grid container justify="space-around" spacing={3}>
						{/* <Grid item xs={2} className={classes.imageupload}>
							<Upload imagelink={imagedit} />
							{loading ? (
								<div
									style={{
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%,-50%)',
										background: 'rgba(0,0,0,0.6)',
										width: '100%',
										height: '100%'
									}}
								>
									<ProgressBar />
								</div>
							) : null}
							<input
								accept="image/*"
								className={classes.input}
								id="icon-button-photo"
								onChange={event => uploadFileHandler(event, 'userProfilePhoto')}
								type="file"
							/>
							<label htmlFor="icon-button-photo">
								<IconButton color="primary" component="span">
									<PhotoCamera />
								</IconButton>
							</label>
						</Grid> */}
						<Grid item xs={6} md={6}>
							<TextField
								name="firstName"
								defaultValue={items.firstName}
								onChange={handlechange}
								label="First Name"
								fullWidth={true}
								className={classes.textField}
							/>
							<TextField
								label="Email ID"
								name="email"
								defaultValue={items.email}
								onChange={handlechange}
								disabled
								fullWidth={true}
								className={classes.textField}
							/>
							<TextField
								select
								label="Role"
								name="role"
								defaultValue={items.role}
								onChange={handlechange}
								helperText="Please select role"
								fullWidth={true}
								className={classes.textField}
							>
								{roles.map(option => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid item xs={6} md={6}>
							<TextField
								label="Last Name"
								name="lastName"
								defaultValue={items.lastName}
								onChange={handlechange}
								fullWidth={true}
								className={classes.textField}
							/>
							<TextField
								label="Mobile No"
								name="phone"
								defaultValue={items.phone}
								onChange={handlechange}
								disabled
								fullWidth={true}
								className={classes.textField}
							/>
						</Grid>
					</Grid>
					<DialogActions className={classes.btn}>
						<button
							className="custommBtn"
							style={{background: "#be3728", marginRight:"20px"}}
							onClick={handleCancel}
						>
							Cancel
						</button>
						<button
							className="custommBtn"
							type="submit"
							onClick={handleSubmit}
						>
							Save
						</button>
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
}

export default React.memo(UserEdit);
