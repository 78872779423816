// import FuseDialog from '@fuse/core/FuseDialog';
// import FuseMessage from '@fuse/core/FuseMessage';
// import FuseScrollbars from '@fuse/core/FuseScrollbars';
// import FuseSuspense from '@fuse/core/FuseSuspense';
// import AppContext from 'app/AppContext';
import React from 'react';
import { useSelector } from 'react-redux';
// import { renderRoutes } from 'react-router-config';
// import FooterLayout1 from './components/FooterLayout1';
// import LeftSideLayout1 from './components/LeftSideLayout1';
// import NavbarWrapperLayout1 from './components/NavbarWrapperLayout1';
// import RightSideLayout1 from './components/RightSideLayout1';
// import clsx from 'clsx';

import PublicLayout from './PublicLayout';
import PrivateLayout from './PrivateLayout';

function Layout(props) {
	// const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	// const appContext = useContext(AppContext);
	// const classes = useStyles(props);
	// const { routes } = appContext;
	// let layout = {layout:'public'};
	const reducer = useSelector(state => state.settings.layout);

	// let isPrivateLayout = localStorage.getItem('isPrivateLayout') ? localStorage.getItem('isPrivateLayout') : false;
	// console.warn('FuseLayout:: rendered');
	// console.log(isPrivateLayout, isPrivateLayout === 'true');

	const layout = reducer.layout === 'private' ? <PrivateLayout /> : <PublicLayout />;

	return layout;
}

export default React.memo(Layout);
// export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Layout));
