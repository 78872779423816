import { combineReducers } from 'redux';
import organisations from './organisations.reducer';
import organisation from './organisation.reducer';
import organisationUpload from './organisationUpload.reducer';
import organizationKyc from './organizationKyc.reducer';
import carrierGetUrlReducer from './getUrl.reducer';

const organisationReducer = combineReducers({
	organisations,
	organisation,
	organisationUpload,
	organizationKyc,
	carrierGetUrlReducer
});

export default organisationReducer;
