/* eslint-disable camelcase */

import React from 'react';
import { useDispatch } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';

import IconButton from '@material-ui/core/IconButton';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import authService from 'app/services/authService';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import * as LayoutActions from 'app/app-layout/store/layoutSlice/actions';
import * as Actions from './store/loginSlice/actions';

const useStyles = makeStyles(theme => ({
	main: {
		fontFamily: 'Nunito',
		height: '100%',
		display: 'flex',
		flex: 1,
		flexDirection: 'column'
	},
	title: { fontSize: 35, color: 'black', textAlign: 'center' },
	icon: { fontSize: 32, color: 'black' },
	form: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
	login: {
		width: '70%',
		color: 'white',
		fontSize: 15,
		fontWeight: 'bold',
		padding: 8,
		textTransform: 'none',
		background: '#0062FF',
		marginTop: 30,
		borderRadius: 7,
		'&:hover': {
			background: '#0062FF'
		}
	},
	forgot: {
		color: '#0062FF',
		fontSize: 16,
		textTransform: 'none',
		borderBottomWidth: 1,
		borderColor: 'black',
		textDecorationLine: 'underline'
	},
	input: { marginTop: 10, width: '70%' },
	footer: { color: 'black', marginBottom: 10, textAlign: 'center' },
	signup: { color: '#0062FF', fontSize: 18, textTransform: 'none', paddingTop: 0 },
	underline: { borderTop: '2px solid #0062FD', width: 40, marginTop: 10, marginBottom: 10 }
}));

function Login(props) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [username, setUser] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [visible, setVisible] = React.useState(false);

	const [loader, setLoader] = React.useState(false);
	const [response, setResponse] = React.useState(null);

	const handleForgotPassword = () => {
		props.setData({ username });
		props.setScreen('InputScreen');
	};

	const handleClose = () => {
		setUser('');
		setPassword('');

		props.onClose();
	};

	const handleLogin = async () => {
		const payload = { username, password };

		const layout = {
			layout: 'private',
			header: true,
			sidebar: false
		};

		setLoader(true);
		setResponse(null);

		const res = await authService.signInWithEmailAndPassword(payload);
		await dispatch(Actions.SignIn(res));

		setLoader(false);
		setResponse(res);

		if (res.status === 200) {
			await dispatch(LayoutActions.getLayout(layout));
			await handleClose();

			// To store organization data in localstorage
			await Actions.setOrganizationInLocalStorage();

			// To store user data in localstorage
			await Actions.setUserInLocalStorage();

			props.history.push({ pathname: './welcome' });
		}
	};

	const errMsg = response?.response?.data?.error_description || 'Sign In Failed';

	return (
		<div className={classes.main}>
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<IconButton onClick={() => handleClose()} style={{ padding: 0 }}>
					<ClearRoundedIcon className={classes.icon} />
				</IconButton>
			</div>

			<div className={classes.title}>Sign In</div>

			<center>
				<div className={classes.underline} />
			</center>

			<div className={classes.form}>
				<TextField
					className={classes.input}
					label="Email / Phone Number"
					value={username}
					inputProps={{ style: { fontSize: 15 } }}
					InputLabelProps={{ style: { fontSize: 18 } }}
					onChange={e => setUser(e.target.value)}
					onKeyPress={e => (e.key === 'Enter' && username && password ? handleLogin() : null)}
				/>

				<TextField
					className={classes.input}
					label="Password"
					type={visible ? 'text' : 'password'}
					value={password}
					InputLabelProps={{ style: { fontSize: 18 } }}
					onChange={e => setPassword(e.target.value)}
					onKeyPress={e => (e.key === 'Enter' && username && password ? handleLogin() : null)}
					InputProps={{
						style: { fontSize: 15 },
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={() => setVisible(!visible)}>
									{visible ? (
										<Visibility style={{ fontSize: 20 }} />
									) : (
										<VisibilityOff style={{ fontSize: 20 }} />
									)}
								</IconButton>
							</InputAdornment>
						)
					}}
				/>

				<div style={{ width: 205, marginTop: 10, alignSelf: 'flex-end' }}>
					<Button className={classes.forgot} onClick={() => handleForgotPassword()}>
						Forgot Password{' '}
					</Button>
				</div>

				<Button variant="contained" className={classes.login} onClick={() => handleLogin()}>
					Sign In{' '}
				</Button>

				<div style={{ marginTop: 20, textAlign: 'center', fontSize: 20 }}>
					{response && response.status !== 200 ? <div style={{ color: 'red' }}>{errMsg}</div> : null}
					{loader ? <div style={{ color: '#0062FF' }}>Checking Details ... Please Wait ... </div> : null}
				</div>
			</div>

			<div className={classes.footer}>
				<div>
					<span>Don't have an account here ? </span>
					<Button className={classes.signup} onClick={() => props.setScreen('SignUp')}>
						Sign Up Now !{' '}
					</Button>
				</div>
			</div>
		</div>
	);
}

export default withRouter(Login);
