/* eslint-disable camelcase */

import React from 'react';
import { useDispatch } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

// import IconButton from '@material-ui/core/IconButton';
// import CancelIcon from '@material-ui/icons/Cancel';
import authService from 'app/services/authService';

import * as Actions from './store/loginSlice/actions';

const useStyles = makeStyles(theme => ({
	main: {
		fontFamily: 'Nunito',
		height: '100%',
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		marginTop: 10
	},
	title: { fontSize: 35, color: 'black', textAlign: 'center' },
	icon: { fontSize: 32, color: 'black' },
	form: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
	button: {
		width: '70%',
		color: 'white',
		fontSize: 15,
		fontWeight: 'bold',
		padding: 8,
		textTransform: 'none',
		background: '#0062FF',
		marginTop: 30,
		borderRadius: 7,
		'&:hover': {
			background: '#0062FF'
		}
	},
	input: { marginTop: 10, width: '70%' },
	footer: { color: 'black', marginBottom: 10, textAlign: 'center' },
	signin: { color: '#0062FF', fontSize: 18, textTransform: 'none', paddingTop: 0 },
	underline: { borderTop: '2px solid #0062FD', width: 40, marginTop: 10, marginBottom: 10 }
}));

function ResetPassword(props) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [password, setPassword] = React.useState('');
	const [confirm, setConfirm] = React.useState('');

	const [loader, setLoader] = React.useState(false);
	const [response, setResponse] = React.useState(null);

	const handleResetPassword = async () => {
		const payload = { search: props.data.username, password };

		setLoader(true);
		setResponse(null);

		const res = await authService.resetPassword(payload);
		await dispatch(Actions.ResetPassword(res));

		if (res.status === 200) props.history.push({ pathname: './welcome' });

		setLoader(false);
		setResponse(res);
	};

	return (
		<div className={classes.main}>
			<div className={classes.title}>Set Password</div>

			<center>
				<div className={classes.underline} />
			</center>

			<div className={classes.form}>
				<TextField
					className={classes.input}
					label="New Password"
					inputProps={{ style: { fontSize: 15 } }}
					InputLabelProps={{ style: { fontSize: 18 } }}
					onChange={e => setPassword(e.target.value)}
				/>

				<TextField
					className={classes.input}
					label="Confirm Password"
					type="password"
					inputProps={{ style: { fontSize: 15 } }}
					InputLabelProps={{ style: { fontSize: 18 } }}
					onChange={e => setConfirm(e.target.value)}
				/>

				{password && confirm ? (
					<div
						style={{
							textAlign: 'right',
							marginTop: 10,
							color: password === confirm ? 'green' : 'red'
						}}
					>
						{password === confirm ? 'Matched' : "Don't Match"}
					</div>
				) : null}

				<Button variant="contained" className={classes.button} onClick={() => handleResetPassword()}>
					Confirm{' '}
				</Button>

				<div style={{ marginTop: 20, textAlign: 'center', fontSize: 20 }}>
					{response && response.status !== 200 ? (
						<div style={{ color: 'red' }}>Set Password Failed</div>
					) : null}
					{loader ? <div style={{ color: '#0062FF' }}>Setting Password ... Please Wait ... </div> : null}
				</div>
			</div>

			<div className={classes.footer}>
				<div>
					<span>Already have an account ? </span>
					<Button className={classes.signin} onClick={() => props.setScreen('Login')}>
						Sign In !{' '}
					</Button>
				</div>
			</div>
		</div>
	);
}

export default withRouter(ResetPassword);
