import { combineReducers } from '@reduxjs/toolkit';

import authMain from 'app/main/auth/store';
import pages from 'app/main/pages/store';

import auth from 'app/auth/store';
import organisations from 'app/main/Kyc/store/reducers';
import organisation from 'app/main/Kyc/redux/organisationSlice';
import profile from 'app/main/profilePage/redux/profileSlice';

// import trip from 'app/main/trips/store/reducers';
// import trips from 'app/main/trips/redux/tripsSlice';
//import trips from 'app/main/tripsNew/redux/tripsSlice';
import organizationKyc from 'app/main/OrganizationKyc/redux/orgKycSlice';

import settings from 'app/app-layout/store';
// import users from 'app/main/settings/store/reducers';
import usersStore from 'app/main/settings/redux/userSlice';
//import customers from 'app/main/customers/redux/customersSlice';
//import suppliers from 'app/main/suppliers/redux/suppliersSlice';
//import accounts from 'app/main/accounts/redux/accountsSlice';
//import carriers from 'app/main/directories/carriers/redux/carriersSlice';
//import drivers from 'app/main/directories/drivers/redux/driversSlice';
import fuse from './fuse';

const createReducer = asyncReducers =>
	combineReducers({
		auth,
		authMain,
		pages,
		fuse,
		organisation,
		organisations,
		//customers,
		profile,
		organizationKyc,
		//trips,
		// trip,
		settings,
		usersStore,
		//suppliers,
		//accounts,
		//carriers,
		//drivers,
		...asyncReducers
	});

export default createReducer;
