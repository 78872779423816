import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

// import Link from '@material-ui/core/Link';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Menu from '@material-ui/core/Menu';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Button from '@material-ui/core/Button';
// import Backdrop from '@material-ui/core/Backdrop';
// import Drawer from '@material-ui/core/Drawer';

import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';

import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import TruckImg from '../../images/Logistics-rafiki@2x.png';

import authService from 'app/services/authService';

import LogoImg from '../../images/Logo.png';
import ConfirmDialog from '../../main/components/ConfirmDialog';

const StyledMenu = withStyles({
	paper: {
		border: '1px solid whitesmoke',
		borderRadius: 0,
		marginTop: 4,
		boxShadow: '0px 3px 6px #00000029'
	}
})(props => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'center'
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'center'
		}}
		{...props}
	/>
));

const useStyles = makeStyles(theme => ({
	banner: { background: '#ffd85f', textAlign: 'center', color: '#000000bf', padding: 8, fontSize: 11 },
	title: {
		fontSize: 16,
		color: '#0660f6',
		textTransform: 'none',
		'&:hover': {
			background: 'transparent'
		}
	},
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '100%',
		paddingLeft: 15,
		paddingRight: 15
	},
	list: { borderColor: '#0062FF', borderRadius: 'unset', height: '100%', display: 'flex', alignItems: 'center' },
	listActive: { borderBottomWidth: 3, '& .MuiButton-root': { color: '#0062FF' } },
	menu: {
		fontFamily: 'Lato',
		fontSize: 15,
		textTransform: 'none',
		marginLeft: 20,
		marginRight: 20,
		'&:hover': {
			background: 'transparent',
			borderWidth: 0,
			// color: 'black !important',
			fontWeight: 'bold !important'
		}
	},
	button: {
		fontFamily: 'Lato',
		fontSize: 13,
		textTransform: 'none',
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		borderColor: '#217af8',
		borderRadius: 7,
		paddingLeft: '0px'
	},
	login: {
		padding: '5px 20px',
		color: '#217af8',
		'&:hover': {
			background: 'white',
			color: '#217af8',
			borderWidth: 1,
			borderColor: '#217af8'
		}
	},
	started: {
		background: '#217af8',
		borderWidth: 0,
		padding: '5px 13px',
		color: 'white',
		'&:hover': {
			background: '#217af8',
			color: 'white !important',
			borderWidth: 0
		}
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	drawer: { borderBottom: '1px solid lightgray', width: 250, textAlign: 'center', padding: 20, marginLeft: 30 },
	media: {
		height: 150,
		margin: 10,
		backgroundSize: 'contain'
	},
	logo: {
		height: 30,
		width: 30,
		marginRight: 10,
		backgroundSize: 'contain'
	},
	dialgoWidth: {
		maxWidth: '700px',
		margin: '0 auto'
	}
}));

function Header(props) {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const [open, setOpen] = React.useState(false);
	const [arrowRef, setArrowRef] = React.useState(null);
	const anchorRef = React.useRef(null);
	const [signOutConfirm, setSignOutConfirm] = useState(false);
	const currentUrl = window.location.pathname || '/trips/unverified';

	const list = [
		{ name: 'Organizations', url: '/org-kyc/unverified' },
		//{ name: 'Trips', url: '/trips/current' },
		// { name: 'Customers', url: '/customers' },
		// { name: 'Suppliers', url: '/suppliers' },
		// { name: 'Reports', url: '/reports' }
		// { name: 'Users', url: '/users' },
		// { name: 'Directories', url: '/directories' },
		// { name: 'Accounts', url: '/accounts' },
		// { name: 'Profile', url: '/profile' }
	];

	const signOut = () => {
		setSignOutConfirm(true);
	};

	const signOutCallback = status => {
		if (status) {
			authService.signOut();
			props.history.push({ pathname: '/' });
		}
		setSignOutConfirm(false);
	};

	const navigateTo = route => {
		props.history.push({
			pathname: '/' + route
		});
		setAnchorEl(null);
		return;
	};

	const handleNotifications = () => {
		setOpen(prevOpen => !prevOpen);
	};

	React.useEffect(() => {
		function handleClickOutside(event) {
			if (anchorRef.current && !anchorRef.current.contains(event.target)) {
				setOpen(false);
			}
		}

		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [anchorRef]);

	return (
		<div style={{ fontFamily: 'Lato' }} id="main_header">
			<div style={{ height: '64px', marginBottom: '10px' }}>
				<AppBar
					position="static"
					style={{ background: 'white', height: '100%', boxShadow: '0px 3px 6px #4A4A4A14' }}
				>
					<Toolbar className={classes.toolbar}>
						<div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => props.history.push('/trips/current')}>
							<CardMedia className={classes.logo} image={LogoImg} />
							<div className={classes.title + ' ffb-secondary'}>Simple LR</div>
						</div>

						<Hidden mdDown>
							<div style={{ display: 'flex', height: '100%' }}>
								{list.map((i, index) => (
									<div
										key={index}
										className={
											classes.list +
											' ' +
											`${
												i.url.split('/')[1] === currentUrl.split('/')[1]
													? classes.listActive
													: null
											}`
										}
									>
										<Button
											className={classes.menu + ' ffb-secondary'}
											onClick={() =>
												props.history.push({
													pathname: i.url
												})
											}
										>
											{i.name}
										</Button>
									</div>
								))}
							</div>
						</Hidden>

						<div className="flex flex-row" style={{ alignItems: 'center', marginLeft: 60 }}>
							<IconButton onClick={handleNotifications} ref={anchorRef}>
								<NotificationsNoneIcon style={{ fontSize: 32, color: '#92929d' }} />
							</IconButton>
							<Popper
								placement="bottom-end"
								disablePortal={false}
								open={open}
								anchorEl={anchorRef.current}
								modifiers={{
									flip: {
										enabled: true
									},
									preventOverflow: {
										enabled: true,
										boundariesElement: 'scrollParent'
									},
									arrow: {
										enabled: true,
										element: arrowRef
									}
								}}
							>
								<span ref={setArrowRef} />
								<Paper style={{ minWidth: '250px' }}>
									<DialogContent>No notifications</DialogContent>
								</Paper>
							</Popper>

							<IconButton onClick={e => setAnchorEl(e.currentTarget)}>
								<AccountCircleIcon style={{ fontSize: 32, color: '#0062ff' }} />
							</IconButton>

							<StyledMenu
								id="customized-menu"
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={() => setAnchorEl(null)}
							>
								<div style={{ width: 100, textAlign: 'left' }}>
									<Button className={classes.button} onClick={() => navigateTo('users')}>
										Users
									</Button>
									<Button className={classes.button} onClick={() => navigateTo('profile')}>
										Profile
									</Button>
									<Button
										className={classes.button}
										style={{ color: '#d93025' }}
										onClick={() => signOut()}
									>
										Sign Out
									</Button>
								</div>
							</StyledMenu>
						</div>
					</Toolbar>
				</AppBar>
			</div>
			<ConfirmDialog
				message="Do you want to log out"
				title="Confirm"
				open={signOutConfirm}
				callBack={signOutCallback}
			/>
		</div>
	);
}

export default withRouter(Header);
