import API from 'app/middleware';
import * as Actions from './index';

const ContactEmail = data => async dispatch => {
	const reqObj = {
		url: `https://wpjstvhzal.execute-api.us-east-2.amazonaws.com/dev/contactEmail`,
		method: 'POST',
		data
	};

	const response = await API(reqObj, 'noToken');

	dispatch(Actions.contact(response));

	return response;
};

export { ContactEmail };
