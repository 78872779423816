import MomentUtils from '@date-io/moment';
// import FuseTheme from '@fuse/core/FuseTheme';
import history from '@history';
import { createGenerateClassName, jssPreset, StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import { create } from 'jss';
import jssExtend from 'jss-plugin-extend';
import rtl from 'jss-rtl';
import React from 'react';
import Provider from 'react-redux/es/components/Provider';
import { Router } from 'react-router-dom';
import AppContext from './AppContext';
import store from './store';

// import { Auth } from './auth';
import Authorization from './auth/Authorization';
import routes from './app-configs/routesConfig';
import Layout from './app-layout';
// import Layout from './app-layout/privateLayout';

const jss = create({
	...jssPreset(),
	plugins: [...jssPreset().plugins, jssExtend(), rtl()],
	insertionPoint: document.getElementById('jss-insertion-point')
});

const generateClassName = createGenerateClassName();

const theme = createMuiTheme({
	typography: {
	  // Tell Material-UI what the font-size on the html element is.
	  htmlFontSize: 12,
	  fontFamily: 'Nunito',
	},
  });

const App = () => {
	return (
		<AppContext.Provider
			value={{
				routes,
				isPublic: true
			}}
		>
			<StylesProvider jss={jss} generateClassName={generateClassName}>
				<ThemeProvider theme={theme}>
				<Provider store={store}>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<Router history={history}>
							<Authorization>
								<Layout />
							</Authorization>
						</Router>
					</MuiPickersUtilsProvider>
				</Provider>
				</ThemeProvider>
			</StylesProvider>
		</AppContext.Provider>
	);
};

export default App;
