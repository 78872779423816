const navigationConfig = [
	{
		id: 'applications',
		title: 'Applications',
		translate: 'APPLICATIONS',
		type: 'group',
		icon: 'apps',
		children: [
			{
				id: 'welcome-component',
				title: 'Welcome',
				translate: 'WELCOME',
				type: 'item',
				icon: 'whatshot',
				url: '/welcome'
			},
			{
				id: 'welcomeSetup-component',
				title: 'WelcomeSetup',
				translate: 'WELCOMESETUP',
				type: 'item',
				icon: 'whatshot',
				url: '/welcome-setup'
			},
			{
				id: 'trips-component',
				title: 'Trips',
				translate: 'TRIPS',
				type: 'item',
				icon: 'EmojiTransportation',
				url: '/unused-lr'

				// children: [
				// 	{
				// 		id: 'unused-lr-child-component',
				// 		title: 'Unused Lr',
				// 		translate: 'UNUSED LR',
				// 		type: 'item',
				// 		icon: 'account_circle',
				// 		url: '/apps/unused-lr'
				// 	},
				// 	{
				// 		id: 'trips-child-component',
				// 		title: 'Trips',
				// 		translate: 'TRIPS',
				// 		type: 'item',
				// 		icon: 'account_circle',
				// 		url: '/apps/trips'
				// 	}
				// ]
			},
			/*{
				id: 'kyc-component',
				title: 'Kyc',
				translate: 'kyc',
				type: 'item',
				icon: 'account_circle',
				url: '/kyc'
			},*/
			{
				id: 'profilepage-component',
				title: 'ProfilePage',
				translate: 'profilepage',
				type: 'item',
				icon: 'account_circle',
				url: '/profile'
			},
			// {
			// 	id: 'profile-component',
			// 	title: 'Profile',
			// 	translate: 'PROFILE',
			// 	type: 'item',
			// 	icon: 'account_circle',
			// 	url: '/profile'
			// },
			{
				id: 'settings-component',
				title: 'Settings',
				translate: 'settings',
				type: 'item',
				icon: 'account_circle',
				url: '/settings'
			},
			{
				id: 'customers-component',
				title: 'Customers',
				translate: 'customers',
				type: 'item',
				icon: 'account_circle',
				url: '/customers'
			}
		]
	}
];

export default navigationConfig;
