import { createSlice } from '@reduxjs/toolkit';

const organisationSlice = createSlice({
	name: 'organisation',
	initialState: {
		organisationWithId: {},
		organisationUpload: {},
		getUrl: {},
		linkEwayBills: {},
		organisationUpdate: {},
		registrationCreate: {},
		registrationUpdate: {},
		tokenApi: {},
		gstin: {},
		error: {},
		loading: false
	},
	reducers: {
		organisationWithId: (state, action) => {
			state.organisationWithId = action.payload;
		},
		errorResponse: (state, action) => {
			state.error = action.payload;
		},
		organisationUpdate: (state, action) => {
			state.organisationUpdate = action.payload;
		},
		registrationCreate: (state, action) => {
			state.registrationCreate = action.payload;
		},
		registrationUpdate: (state, action) => {
			state.registrationUpdate = action.payload;
		},
		gstin: (state, action) => {
			state.gstin = action.payload;
		},
		tokenApi: (state, action) => {
			state.tokenApi = action.payload;
		},
		linkEwayBills: (state, action) => {
			state.linkEwayBills = action.payload;
		},
		organisationUpload: (state, action) => {
			state.organisationUpload = action.payload;
		},
		getUrl: (state, action) => {
			state.getUrl = action.payload;
		},
		kycLoader: (state, action) => {
			state.loading = action.payload;
		}
	}
});

export default organisationSlice.reducer;

export const {
	organisationWithId,
	organisationUpdate,
	registrationCreate,
	registrationUpdate,
	tokenApi,
	gstin,
	errorResponse,
	linkEwayBills,
	organisationUpload,
	getUrl,
	kycLoader
} = organisationSlice.actions;
