import React, { useState, useEffect, useCallback } from 'react';
import {
	// Button,
	Dialog,
	DialogActions,
	// DialogTitle,
	Grid,
	// Divider,
	MenuItem
	// IconButton
} from '@material-ui/core';
import Formsy from 'formsy-react';
import { TextFieldFormsy } from '@fuse/core/formsy';
// import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
// import PhotoCamera from '@material-ui/icons/PhotoCamera';

import blue from '@material-ui/core/colors/blue';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
// import * as actions from './store/actions';
import * as actions from './redux/userApi.js';
// import ProgressBar from './../Kyc/FileLoadingProgress';
// import Upload from './upload.js';
const useStyles = makeStyles(theme => ({
	btn: {
		justifyContent: 'flex-end',
		marginTop: '20px',
		paddingRight: '0px',
		marginBottom: '16px'
	},
	root: {
		padding: "20px 30px"
	},
	input: {
		display: 'none'
	},
	button: {
		color: blue[900],
		margin: 10,
		width: '20px',
		height: '15px'
	},
	imageupload: {
		display: 'block',
		marginTop: '26px',
		textAlign: '-webkit-center'
	},
	textField: {
		marginTop: "10px"
	}
}));

const roles = [
	{
		value: 'admin',
		label: 'ADMIN'
	},
	{
		value: 'employee',
		label: 'EMPLOYEE'
	}
];

const initialvalues = {
	firstName: '',
	lastName: '',
	email: '',
	phone: '',
	role: 'admin',
	profilePic: ''
};
function UserAdd(props) {
	// console.log(props, 'propsadd');
	const classes = useStyles();
	const [values, setValues] = useState(initialvalues);
	// const [images, setImages] = useState('');
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const {userUpload} = useSelector(state => state.usersStore);
	const image = userUpload && userUpload.data ? userUpload.data[0] : null;
	const handlechange = e => {
		const { name, value } = e.target;
		setValues({
			...values,
			[name]: value
		});
	};
	const handleSubmit = () => {
		// event.preventDefault();
		dispatch(actions.createUserApi(values));
		props.handleClose();
		setValues(initialvalues);
	};
	const handleClearValues = () => {
		// setImages('');
		setValues(initialvalues);
		// image = null;
		dispatch(actions.imageResetApi());
	};

	useEffect(() => {
		if (image && image !== '' && image !== undefined) {
			// setImages(image);

			setValues({
				...values,
				profilePic: image
			});
			setLoading(false);
		}
	}, [userUpload, image]);
	const uploadFileHandler = useCallback((event, docType) => {
		const file = event.target.files[0];
		// console.log(file, 'file');
		const id = 'phone';
		const formData = new FormData();
		formData.append('documents', file);
		setLoading(true);
		dispatch(actions.uploadUserApi(formData, id, docType));
	}, [dispatch]);
	return (
		<>
			<Dialog maxWidth="sm" open={props.open} aria-labelledby="form-dialog-title">
				{/* <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
				<Divider /> */}
				<p style={{color: "#000000DE",padding: "14px 20px"}}>{props.title}</p>
				<hr
					style={{
						height: 0.5,
						borderColor: '#ccc'
					}}
				/>
				<Formsy className={classes.root} onSubmit={handleSubmit}>
					<Grid container justify="space-around" spacing={3}>
						{/* <Grid item xs={2} className={classes.imageupload}>
							<Upload imagelink={image} />
							{loading ? (
								<div
									style={{
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%,-50%)',
										background: 'rgba(0,0,0,0.6)',
										width: '100%',
										height: '100%'
									}}
								>
									<ProgressBar />
								</div>
							) : null}
							<input
								accept="image/*"
								className={classes.input}
								id="icon-button-photo"
								onChange={event => uploadFileHandler(event, 'userProfilePhoto')}
								type="file"
							/>
							<label htmlFor="icon-button-photo">
								<IconButton color="primary" component="span">
									<PhotoCamera />
								</IconButton>
							</label>
						</Grid> */}
						<Grid item xs={6} md={6}>
							<TextFieldFormsy
								required
								name="firstName"
								type="text"
								value={values.firstName}
								onChange={handlechange}
								label="First Name"
								autoComplete='off'
								validations={{
									minLength: 4
								}}
								validationErrors={{
									minLength: 'Min character length is 4'
								}}
								fullWidth={true}
								className={classes.textField}
							/>
							<TextFieldFormsy
								required
								label="Email ID"
								name="email"
								type="email"
								value={values.email}
								onChange={handlechange}
								autoComplete='off'
								validations="isEmail"
								fullWidth={true}
								className={classes.textField}
							/>
							<TextFieldFormsy
								select
								required
								label="Role"
								name="role"
								value={values.role}
								onChange={handlechange}
								fullWidth={true}
								className={classes.textField}
							>
								{roles.map(option => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</TextFieldFormsy>
						</Grid>
						<Grid item xs={6} md={6}>
							<TextFieldFormsy
								required
								label="Last Name"
								name="lastName"
								type="text"
								value={values.lastName}
								onChange={handlechange}
								autoComplete='off'
								validations={{
									minLength: 3
								}}
								validationErrors={{
									minLength: 'Min character length is 3'
								}}
								fullWidth={true}
								className={classes.textField}
							/>
							<TextFieldFormsy
								required
								label="Mobile No"
								name="phone"
								type="number"
								value={values.phone}
								onChange={handlechange}
								autoComplete='off'
								validations={{
									minLength: 10,
									maxLength: 10
								}}
								validationErrors={{
									minLength: 'Min character length is 10',
									maxLength: 'Max character length is 10'
								}}
								fullWidth={true}
								className={classes.textField}
							/>
						</Grid>
					</Grid>

					<DialogActions className={classes.btn}>
						<button
							className="custommBtn"
							style={{background: "#be3728", marginRight:"20px"}}
							onClick={() => {
								handleClearValues();
								props.handleClose();
							}}
						>
							Cancel
						</button>
						<button className="custommBtn">
							Save
						</button>
					</DialogActions>
				</Formsy>
			</Dialog>
		</>
	);
}
export default React.memo(UserAdd);
