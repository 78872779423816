import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import HandWave from './images/hand-wave.png';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: '1',
		display: 'flex',
		alignItems: 'center',
		fontFamily: 'Nunito',
		textAlign: 'center',
		background: '#9e9ea5'
	},
	welcomeIcon: {
		width: '50px',
		padding: '0px 8px 8px 0px'
	},
	welcome: {
		fontSize: '38px'
	},
	welcomeNote: {
		marginBottom: '20px',
		fontSize: '16px',
		opacity: '41%'
	},
	rowBox: {
		width: '200px',
		margin: '10px'
	},
	rowBoxNumber: {
		fontSize: '15px',
		color: '#FFFFFF',
		margin: '15px auto',
		width: '45px',
		height: '45px',
		borderRadius: '50%',
		padding: '13px',
		backgroundColor: '#E87E03'
	},
	rowBoxMiddle: {
		fontSize: '18px',
		padding: '10px 10px 10px 10px'
	},
	rowBoxFooter: {
		fontSize: '12px'
	},
	getStarted: {
		width: '160px',
		height: '40px',
		color: '#fafafb',
		fontSize: '13px',
		backgroundColor: '#0062ff',
		borderRadius: '8px',
		marginTop: '50px',
		textTransform: 'none',
		'&:hover': {
			backgroundColor: '#0062ff'
		}
	},
	gridCss: {
		padding: '40px 10px',
		width: '800px',
		margin: '0 auto',
		background: '#fff'
	}
}));

function WelcomePage(props) {
	const classes = useStyles();

	const getStarted = () => {
		props.history.push({ pathname: '/kyc' });
	};

	return (
		<div className={classes.root}>
			<Grid
				container
				spacing={3}
				direction="column"
				justify="center"
				alignItems="center"
				className={classes.gridCss}
			>
				<Grid container direction="row" justify="center" alignItems="center">
					<img src={HandWave} alt="HandWave" className={classes.welcomeIcon} />
					<div className={classes.welcome}>Welcome, Krab!</div>
				</Grid>
				<Grid item className={classes.welcomeNote}>
					<div>Hey Transporters, so glad you’re here!</div>
					<div>Now let’s help you set up, won’t take you more than 5 min :)</div>
				</Grid>
				<Grid container spacing={1} direction="row" justify="center" alignItems="center">
					<div className={classes.rowBox}>
						<div className={classes.rowBoxNumber}>01</div>
						<div className={classes.rowBoxMiddle}>Set LR Template</div>
						<div className={classes.rowBoxFooter}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
						</div>
					</div>
					<div className={classes.rowBox}>
						<div className={classes.rowBoxNumber}>02</div>
						<div className={classes.rowBoxMiddle}>Link E-waybill</div>
						<div className={classes.rowBoxFooter}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
						</div>
					</div>
					<div className={classes.rowBox}>
						<div className={classes.rowBoxNumber}>03</div>
						<div className={classes.rowBoxMiddle}>Import Data</div>
						<div className={classes.rowBoxFooter}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
						</div>
					</div>
				</Grid>
				<Grid item>
					<Button className={classes.getStarted} onClick={getStarted}>
						Lets Get Started
					</Button>
				</Grid>
			</Grid>
		</div>
	);
}

export default WelcomePage;
