import * as actions from '../actions/organisation.actions';

const organisationUploadReducer = function (state = {}, action) {
	switch (action.type) {
		case actions.UPLOAD_ORGANISATION:
			if (action.payload) {
				// console.log(action.payload, 'payload');
				return {
					...action.payload
				};
			}
			break;
		case actions.UPLOAD_ORGANISATION_ERROR:
			if (action.payload) {
				return {
					...action.payload
				};
			}
			break;
		default:
			return state;
	}
};

export default organisationUploadReducer;
