import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import BlueTruck from '../../../images/blueTruck.png';

// import Button from '@material-ui/core/Button';
// import MessageIcon from '@material-ui/icons/Message';

const useStyles = makeStyles(theme => ({
	container: {
		maxWidth: '100%',
		height: '70%',
		fontFamily: 'Poppins',
		color: '#1D2238',
		marginTop: 50,
		marginBottom: 50,
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			height: '100%'
		}
	},
	title: {
		fontSize: 50,
		fontFamily: 'Poppins',
		fontWeight: 'bold',
		[theme.breakpoints.down('sm')]: {
			fontSize: 30
		}
	},
	subTitle: { fontSize: 20 },
	text: { fontSize: 15, color: 'gray' },
	truck: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }
}));

function Support(props) {
	const classes = useStyles();

	return (
		<Container className={classes.container}>
			<Grid container spacing={3}>
				<Grid item xs={12} md={6}>
					<div className={classes.truck}>
						<img src={BlueTruck} alt="Collab" style={{ width: 500 }} />
					</div>
				</Grid>

				<Grid item xs={12} md={6}>
					<div className={classes.title}>Frequently asked questions</div>

					<Grid container spacing={3} style={{ marginTop: 20 }}>
						<Grid item xs={12} md={6}>
							<div className={classes.subTitle}>What is Simple LR?</div>

							<div className={classes.text}>
								Simple LR is an online tool which helps transporters generate LR/bilti by entering their
								E-way bill.
							</div>
						</Grid>

						<Grid item xs={12} md={6}>
							<div className={classes.subTitle}>Can I print from my Mobile?</div>

							<div className={classes.text}>
								You can simply connect your mobile to a printer through a micro OTG cable and take a
								print.
							</div>
						</Grid>

						<Grid item xs={12} md={6}>
							<div className={classes.subTitle}>How accurate is my photo based LR?</div>

							<div className={classes.text}>
								Our tool is built using advanced AI-ML technology.We recommned a mobile device with good
								camera quality for best experience.
							</div>
						</Grid>

						<Grid item xs={12} md={6}>
							<div className={classes.subTitle}>How safe is my data?</div>

							<div className={classes.text}>
								Data privacy is our priority.You can authenticate who can manage the trips, without
								compromising on security.
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
}

export default Support;
