import React from 'react';
import { useDispatch } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Button, IconButton, Collapse } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

import * as Actions from './store/loginSlice/actions';

const useStyles = makeStyles(theme => ({
	main: {
		fontFamily: 'Nunito',
		height: '100%',
		display: 'flex',
		flex: 1,
		flexDirection: 'column'
	},
	title: { fontSize: 35, color: 'black', textAlign: 'center', flex: 1 },
	icon: { fontSize: 32, color: 'black', flex: 1 },
	subHead: { fontSize: 17, color: 'gray', marginTop: 10 },
	input: { marginTop: 10, flex: 1, width: '70%' },
	button: {
		width: '40%',
		color: 'white',
		fontSize: 15,
		fontWeight: 'bold',
		padding: 8,
		textTransform: 'none',
		background: '#0062FF',
		marginTop: 30,
		marginBottom: 20,
		borderRadius: 7,
		'&:hover': {
			background: '#0062FF'
		}
	},
	footer: { color: 'black', marginBottom: 10, textAlign: 'center', marginTop: 15 },
	signin: { color: '#0062FF', fontSize: 18, textTransform: 'none', paddingTop: 0 },
	underline: { borderTop: '2px solid #0062FD', width: 40, marginTop: 10, marginBottom: 10 },
	info: {
		marginTop: 30,
		marginBottom: 20,
		color: 'gray',
		fontFamily: 'Nunito',
		fontSize: 16
	}
}));

function OtpScreen(props) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [username, setUser] = React.useState(props?.data?.username || '');
	// const [btnDisabled, setBtnDisabled] = useState(true);
	const [alert, setAlert] = React.useState(false);
	const [alertMessage, setAlertMessage] = React.useState("");
	
	const handleClose = () => {
		setUser('');
		props.setData({});

		props.onClose();
	};

	const userLength = username.length;
	// console.log(userLength,"length");

	const handleRequest = async () => {
		const loginResponse = await dispatch(Actions.RequestOTP({ phoneNumber: username }));
		if( loginResponse.status == 400 ){
			setAlert(true);
			setAlertMessage(loginResponse.response.message);
		} else {
			props.setData({ username });
			props.setScreen('OtpScreen');
		}
		//props.mixpanel.identify(username);
		//props.mixpanel.track('Button Click', { name: 'Request OTP' });
	};

	return (
		<div className={classes.main}>
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<div className={classes.title}>Log In</div>

				<IconButton onClick={() => handleClose()} style={{ padding: 0 }}>
					<ClearRoundedIcon className={classes.icon} />
				</IconButton>
			</div>

			<center>
				<div className={classes.underline} />
			</center>

			<center>
				<TextField
					className={classes.input}
					label="Phone Number"
					value={username}
					inputProps={{ style: { fontSize: 15 } }}
					InputLabelProps={{ style: { fontSize: 18 } }}
					onChange={e => setUser(e.target.value)}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start" color="black">
								+91
							</InputAdornment>
						)
					}}
				/>
				{userLength === 10 ? (
					<Button variant="contained" className={classes.button} onClick={() => handleRequest()}>
						Request OTP
					</Button>
				) : (
					<div className={classes.info}>Please Enter 10 digits PhoneNumber</div>
				)}
				<Collapse in={alert}>
					<Alert
						severity="info"
						icon={false}
						action={
							<IconButton
								aria-label="close"
								color="inherit"
								size="small"
								onClick={ () => { setAlert(false) }}
							>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						}
					>
						<strong>{alertMessage}</strong>
					</Alert>
				</Collapse>
			</center>

			{/*	<div className={classes.footer}>
				<div>
					<span>Already have an account ? </span>
					<Button className={classes.signin} onClick={() => props.setScreen('Login')}>
						Sign In !{' '}
					</Button>
				</div>
			</div> */}
		</div>
	);
}

export default withRouter(OtpScreen);
