import { createSlice } from '@reduxjs/toolkit';

const profileSlice = createSlice({
	name: 'profile',
	initialState: {
		getEwbCreds: {},
		getProfile: {},
		getUserProfile:{},
		profileUpload: {},
		updateUser: {},
		createUser: {},
		changePassword: {},
		gstin: {},
		error: {},
		loading: false
	},
	reducers: {
		getEwbCreds: (state, action) => {
			state.getEwbCreds = action.payload;
		},
		gstin: (state, action) => {
			state.gstin = action.payload;
		},
		getProfile: (state, action) => {
			state.getProfile = action.payload;
		},
		getUserProfile: (state, action) => {
			state.getUserProfile = action.payload;
		},
		profileUpload: (state, action) => {
			state.profileUpload = action.payload;
		},
		updateUser: (state, action) => {
			state.updateUser = action.payload;
		},
		createUser: (state, action) => {
			state.createUser = action.payload;
		},
		changePassword: (state, action) => {
			state.changePassword = action.payload;
		},
		errorResponse: (state, action) => {
			state.error = action.payload;
		},
		profileLoader: (state, action) => {
			state.loading = action.payload;
		}
	}
});

export default profileSlice.reducer;

export const {
	getEwbCreds,
	getProfile,
	getUserProfile,
	gstin,
	updateUser,
	errorResponse,
	createUser,
	changePassword,
	kycLoader,
	profileUpload
} = profileSlice.actions;
