import API from 'app/middleware';
import apiConfig from 'app/app-configs/apiConfig';
import authService from 'app/services/authService';
import * as Actions from './index';

const CURRENT_URL = apiConfig.baseUrl;
// const CURRENT_URL = 'https://api-dev.krab.ai/v1/registration-ms';

const SignIn = data => async dispatch => {
	dispatch(Actions.login(data));
};

const SignUp = data => async dispatch => {
	dispatch(Actions.signUp(data));
};

const ResetPassword = data => async dispatch => {
	dispatch(Actions.resetPassword(data));
};

const RequestOTP = data => async dispatch => {
	const reqObj = {
		url: `${CURRENT_URL}/registration-ms/api/admin/login`,
		method: 'POST',
		data
	};

	const response = await API(reqObj, 'noToken');
	// if( response.status == 400 ){
	// 	return response;
	// }

	dispatch(Actions.requestOTP(response));

	return response;
};

const VerifyOTP = data => async dispatch => {
	const reqObj = {
		url: `${CURRENT_URL}/registration-ms/api/otp-verify`,
		method: 'POST',
		data
	};

	const response = await API(reqObj, 'noToken');

	dispatch(Actions.verifyOTP(response));

	return response;
};

const setOrganizationInLocalStorage = async () => {
	const tokenData = await authService.getTokenData();
	const id = tokenData?.organization_id || null;

	const reqObj = {
		method: 'GET',
		url: `${CURRENT_URL}/organizations-ms/api/organization/${id}`
	};

	const organizationData = await API(reqObj);
	const organization = organizationData?.response?.status === 'Success' ? organizationData.response.data : null;
	localStorage.setItem("organization", JSON.stringify(organization));

	return organization;
};

const setUserInLocalStorage = async () => {

	const reqObj = {
		method: 'GET',
		url: `${CURRENT_URL}/user-ms/api/users`
	};

	const userData = await API(reqObj);
	const user = userData?.response?.status === 'Success' ? userData.response.data : null;
	localStorage.setItem("user", JSON.stringify(user));
	//console.log(user, 'user');
	return user;
};

export { SignIn, SignUp, ResetPassword, RequestOTP, VerifyOTP, setOrganizationInLocalStorage, setUserInLocalStorage };
