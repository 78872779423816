import axios from 'axios';

const API = async (reqObj, type) => {
	try {
		const response = await axios(reqObj);

		return {
			response: response.data,
			status: response.status
		};
	} catch (error) {
		return {
			response: error?.response?.data || {},
			status: error?.response?.status || null
		};
	}
};

export default API;
