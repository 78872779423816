import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

// import Button from '@material-ui/core/Button';
// import CardMedia from '@material-ui/core/CardMedia';

import RoadWeb from '../../../images/roadWeb.png';

import Print from '../../../images/Print.svg';
import Share from '../../../images/Share.svg';
import Safe from '../../../images/Safe.svg';
import Money from '../../../images/Money.svg';

const useStyles = makeStyles(theme => ({
	container: {
		maxWidth: '100%',
		fontFamily: 'Poppins',
		color: 'white',
		background: `url(${RoadWeb})`
	},
	title: {
		fontSize: 50,
		fontWeight: 'bold',
		[theme.breakpoints.down('xs')]: {
			fontSize: 30
		}
	},
	video: {
		width: 500,
		height: 400,
		borderRadius: 10,
		[theme.breakpoints.down('xs')]: {
			width: 350,
			height: 300
		}
	},
	mainDiv: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	subDiv: {
		width: 270,
		height: 170,
		borderRadius: 10,
		background: '#2e3344e6',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column'
	},
	text: { fontWeight: 'bold', marginTop: 20, fontSize: 20 }
}));

function AboutUs(props) {
	const classes = useStyles();

	return (
		<Container className={classes.container}>
			<Grid container spacing={3} style={{ paddingTop: 30, paddingBottom: 30 }}>
				<Grid item xs={12}>
					<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
						<div className={classes.title}>SIMPLE-SWIFT-SECURE</div>

						<div style={{ marginTop: 20, marginBottom: 20 }}>
							<iframe
								className={classes.video}
								src="https://www.youtube.com/embed/xIyjc0NHjwk?showinfo=0"
								frameborder="0"
								allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
								allowfullscreen
							></iframe>
						</div>
					</div>
				</Grid>

				<Grid item xs={12} sm={6} md={3}>
					<div className={classes.mainDiv}>
						<div className={classes.subDiv}>
							<img width="70" height="90" src={Print} alt="Print_LR" />
							<div className={classes.text}>PRINT LR</div>
						</div>
					</div>
				</Grid>

				<Grid item xs={12} sm={6} md={3}>
					<div className={classes.mainDiv}>
						<div className={classes.subDiv}>
							<img width="70" height="90" src={Share} alt="Share" />
							<div className={classes.text}>SHARE & TRACK</div>
						</div>
					</div>
				</Grid>

				<Grid item xs={12} sm={6} md={3}>
					<div className={classes.mainDiv}>
						<div className={classes.subDiv}>
							<img width="70" height="90" src={Safe} alt="Safe" />
							<div className={classes.text}>SAFE & SECURE</div>
						</div>
					</div>
				</Grid>

				<Grid item xs={12} sm={6} md={3}>
					<div className={classes.mainDiv}>
						<div className={classes.subDiv}>
							<img width="70" height="90" src={Money} alt="Expense" />
							<div className={classes.text}>MANAGE EXPENSES</div>
						</div>
					</div>
				</Grid>
			</Grid>
		</Container>
	);
}

export default AboutUs;
