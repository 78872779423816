import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
	name: 'login',
	initialState: {},
	reducers: {
		login: (state, action) => {
			state.login = action.payload;
		},
		signUp: (state, action) => {
			state.signUp = action.payload;
		},
		resetPassword: (state, action) => {
			state.resetPassword = action.payload;
		},
		requestOTP: (state, action) => {
			state.requestOTP = action.payload;
		},
		verifyOTP: (state, action) => {
			state.verifyOTP = action.payload;
		}
	}
});

export const { login, signUp, resetPassword, requestOTP, verifyOTP } = slice.actions;

export default slice.reducer;
