import React from 'react';
import { withRouter } from 'react-router-dom';

// import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import Drawer from '@material-ui/core/Drawer';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
// import AmpStoriesOutlinedIcon from '@material-ui/icons/AmpStoriesOutlined';

import Auth from 'app/main/auth';

import TruckImg from '../../images/Logistics-rafiki@2x.png';
import LogoImg from '../../images/Logo.png';

const useStyles = makeStyles(theme => ({
	appBar: {
		background: '#0c50b1',
		height: '100%',
		paddingLeft: 40,
		paddingRight: 40,
		[theme.breakpoints.only('xs')]: {
			paddingLeft: 0,
			paddingRight: 0
		}
	},
	title: {
		fontSize: 20,
		color: 'white',
		textTransform: 'none',
		'&:hover': {
			background: 'transparent'
		}
	},
	subTitle: { color: 'white', fontSize: 10, fontWeight: 'bold' },
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '100%',
		paddingLeft: 15,
		paddingRight: 15,
		boxShadow: '0 2px 4px -1px rgba(0,0,0,0.25)'
	},
	list: { borderColor: 'white', borderRadius: 'unset', height: '100%', display: 'flex', alignItems: 'center' },
	menu: {
		fontFamily: 'Poppins',
		fontSize: 15,
		textTransform: 'none',
		marginLeft: 20,
		marginRight: 20,
		'&:hover': {
			background: 'transparent',
			borderWidth: 0,
			color: 'white !important',
			fontWeight: 'bold !important'
		}
	},
	button: {
		fontFamily: 'Poppins',
		fontSize: 13,
		textTransform: 'none',
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		borderColor: '#217af8',
		borderRadius: 7
	},
	login: {
		padding: '5px 20px',
		color: 'white',
		borderWidth: 2,
		borderColor: 'white',
		'&:hover': {
			color: 'white',
			borderWidth: 2,
			borderColor: 'white'
		}
	},
	started: {
		background: 'white',
		borderWidth: 0,
		padding: '5px 13px',
		color: 'white',
		'&:hover': {
			background: 'white',
			color: 'white !important',
			borderWidth: 0
		}
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	drawer: { borderBottom: '1px solid lightgray', width: 250, textAlign: 'center', padding: 20, marginLeft: 30 },
	media: {
		height: 150,
		margin: 10,
		backgroundSize: 'contain'
	},
	logo: {
		height: 30,
		width: 30,
		marginRight: 10,
		backgroundSize: 'contain'
	}
}));

// function updateLayout(isPrivateLayout) {
// 	if (isPrivateLayout === 'true') {
// 		localStorage.setItem('isPrivateLayout', 'false');
// 	} else {
// 		localStorage.setItem('isPrivateLayout', 'true');
// 	}
// }

function Header(props) {
	const classes = useStyles();

	const [screen, setScreen] = React.useState('');
	const [open, setOpen] = React.useState(false);
	const [drawer, setDrawer] = React.useState(false);

	const currentUrl = window.location.hash || '#home';

	const list = [
		{ name: 'Home', url: '#home' },
		{ name: 'About', url: '#about-us' },
		{ name: 'Support', url: '#support' },
		{ name: 'Contact', url: '#contact' }
	];

	const handleClick = val => {
		//if (val === 'InputScreen') props.mixpanel.track('Button Click', { name: 'Log In' });

		setScreen(val);
		setOpen(true);
	};

	return (
		<div style={{ fontFamily: 'Poppins' }}>
			<div style={{ height: '70px' }}>
				<AppBar position="static" className={classes.appBar}>
					<Toolbar className={classes.toolbar}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<Hidden lgUp>
								<IconButton onClick={() => setDrawer(!drawer)}>
									<MenuIcon style={{ fontSize: 30, fontWeight: 'bold', color: 'white' }} />
								</IconButton>

								<Drawer anchor="right" open={drawer} onClose={() => setDrawer(false)}>
									<CardMedia className={classes.media} image={TruckImg} />

									{list.map((i, index) => (
										<div key={index} className={classes.drawer}>
											<a
												href={i.url}
												className={classes.menu}
												onClick={() => setDrawer(false)}
												style={{
													fontWeight: i.url === currentUrl ? 'bold' : 'normal',
													color: i.url === currentUrl ? '#217af8' : 'gray'
												}}
											>
												{i.name}
											</a>
										</div>
									))}
								</Drawer>
							</Hidden>

							<div>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									{/* <CardMedia className={classes.logo} image={LogoImg} /> */}
									<div className={classes.title + ' ffsb-secondary'}>Simple LR</div>
								</div>
								<div className={classes.subTitle}>Powered by Krab</div>
							</div>
						</div>

						<Hidden mdDown>
							<div style={{ display: 'flex', height: '100%' }}>
								{list.map((i, index) => (
									<div
										key={index}
										className={classes.list}
										style={{ borderBottomWidth: i.url === currentUrl ? 3 : 0 }}
									>
										<a
											href={i.url}
											className={classes.menu + ' ffb-secondary'}
											style={{
												fontWeight: i.url === currentUrl ? 'bold' : 'normal',
												color: i.url === currentUrl ? 'white' : 'lightgray'
											}}
										>
											{i.name}
										</a>
									</div>
								))}
							</div>
						</Hidden>

						<div>
							<Button
								variant="outlined"
								className={`${classes.button} ${classes.login + ' ffsb-secondary'}`}
								onClick={() => handleClick('InputScreen')}
							>
								Log In
							</Button>

							{/*<Button
								variant="outlined"
								className={`${classes.button} ${classes.started + " ffsb-secondary"}`}
								onClick={() => handleClick('SignUp')}
							>
								Get Started
							</Button>*/}
						</div>
					</Toolbar>
				</AppBar>
			</div>

			<Backdrop open={open} className={classes.backdrop}>
				<div style={{ background: 'white', borderRadius: 5 }}>
					<Auth key={screen} onClose={() => setOpen(false)} screen={screen} reset={() => setScreen('')} />
				</div>
			</Backdrop>
		</div>
	);
}

export default withRouter(Header);
