// Internet Explorer 11 requires polyfills and partially supported by this project.
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
// import 'typeface-muli';
// import './i18n';
// import './react-chartjs-2-defaults';
import './styles/index.css';
import App from 'app/App';

// MixPanel for analytics and event tracking
import mixpanel from 'mixpanel-browser';
import { MixpanelProvider, MixpanelConsumer } from 'react-mixpanel';

import * as serviceWorker from './serviceWorker';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

ReactDOM.render(
	<MixpanelProvider mixpanel={mixpanel}>
		<App />
	</MixpanelProvider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
