const keycloakDevConfig = {
	realm: 'development',
	url: 'https://api-dev.krab.ai/auth/',
	clientId: 'development'
};

const keycloakProdConfig = {
	realm: 'production',
	url: 'https://api.krab.ai/auth',
	clientId: 'production'
};

export const AUTH_CONFIG_KEYCLOAK = process.env.REACT_APP_ENV === 'production' ? keycloakProdConfig : keycloakDevConfig;
export const AUTH_CONFIG = process.env.REACT_APP_ENV === 'production' ? keycloakProdConfig : keycloakDevConfig;
