import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles(theme => ({
	main: {
		display: 'flex',
		flexDirection: 'column',
		background: '#14171d',
		color: 'white',
		padding: 20,
		justifyContent: 'space-around'
	},
	subDiv: {
		display: 'flex',
		justifyContent: 'center',
		margin: 20
	},
	menuDiv: {
		display: 'flex',
		justifyContent: 'center',
		[theme.breakpoints.only('xs')]: {
			flexDirection: 'column',
			alignItems: 'center'
		}
	},
	title: { fontFamily: 'Lato', fontWeight: 'bold', fontSize: 20, color: 'white', textTransform: 'none' },
	toolbar: { display: 'flex', justifyContent: 'space-around', alignItems: 'center' },
	button: {
		fontSize: 17,
		color: 'white',
		textTransform: 'none',
		marginRight: 20,
		marginLeft: 20
	},
	grid: { height: '100%', background: 'white', padding: 10 },
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		height: '100%'
	},
	icon: { color: '#14171d', fontSize: 40, padding: 8, borderRadius: 50, background: 'white', marginRight: 20 },
	menu: {
		fontFamily: 'Lato',
		fontSize: 15,
		textTransform: 'none',
		margin: 20,
		'&:hover': {
			background: 'transparent',
			borderWidth: 0,
			color: 'black !important',
			fontWeight: 'bold !important'
		}
	},
	copyright: {
		fontSize: 15,
		textAlign: 'center',
		margin: 20,
		[theme.breakpoints.only('xs')]: {
			fontSize: 13
		}
	}
}));

function Footer(props) {
	const classes = useStyles();

	// const list = [
	// 	{ name: 'Home', url: '#home' },
	// 	{ name: 'About', url: '#about-us' },
	// 	{ name: 'Support', url: '#support' },
	// 	{ name: 'Contact', url: '#contact' }
	// ];

	return (
		<div className={classes.main}>
			<div className={classes.subDiv}>
				<FacebookIcon className={classes.icon} />
				<TwitterIcon className={classes.icon} />
				<LinkedInIcon className={classes.icon} />
			</div>

			{/* <div className={classes.menuDiv}>
				{list.map((i, index) => (
					<a key={index} href={i.url} className={classes.menu + " ffsb-primary"}>
						{i.name}
					</a>
				))}
			</div> */}

			<div className={classes.copyright + " ffr-primary"}>
				{' '}
				<div>
					2020 © SimpleLR, All rights reserved, Built with love in India
					<span role="img" aria-label="heart">
						{' '}
						❤️
					</span>
				</div>
			</div>
		</div>
	);
}

export default withRouter(Footer);
