import React from 'react';

const reportsConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/reports',
			component: React.lazy(() => import('./Reports')),
			isPrivate: true
		}
	]
};

export default reportsConfig;
