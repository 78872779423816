// Configuration file this app

const devConfig = {
	// baseUrl: "http://localhost:5001/v1",
	// baseUrl: 'https://api-dev.finovo.in/v1',
	baseUrl: 'https://api-dev.krab.ai/v1'
};

const prodConfig = {
	baseUrl: 'https://api.krab.ai/v1'
};

// const prodConfig = {
// 	baseUrl: process.env.REACT_APP_BASE_URL_PROD
// };
//
// const devConfig = {
// 	baseUrl: process.env.REACT_APP_BASE_URL_DEV
// };
// // console.log(devConfig,"devConfig")
// // const localConfig = {
// // 	baseUrl: "http://localhost:5001/v1"
// // }
//
const config = process.env.REACT_APP_ENV === 'production' ? prodConfig : devConfig;

const apiConfig = {
	baseUrl: config.baseUrl
};

// export config;
export default apiConfig;
