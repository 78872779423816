import { makeStyles } from '@material-ui/core/styles';
import AppContext from 'app/AppContext';
import React, { useContext } from 'react';
import { renderRoutes } from 'react-router-config';

// import Sidebar from './Sidebar';
import FuseMessage from '@fuse/core/FuseMessage';
import Header2 from './Header2';
import AppSuspense from './AppSuspense';

const useStyles = makeStyles({
	content: {
		position: 'relative',
		display: 'flex',
		'overflow-y': 'auto',
		'overflow-x': 'hidden',
		flex: '1 1 auto',
		flexDirection: 'column',
		width: '100%',
		'-webkit-overflow-scrolling': 'touch',
		zIndex: 2
	},
	contentWrapper: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		zIndex: 3,
		overflow: 'hidden',
		flex: '1 1 auto'
	},
	wrapper: {
		display: 'flex',
		position: 'relative',
		width: '100%',
		height: '100%',
		flex: '1 1 auto'
	}
});

function PrivateLayout(props) {
	const appContext = useContext(AppContext);
	const { routes } = appContext;
	const classes = useStyles();

	return (
		<div className="flex flex-1 flex-col overflow-hidden relative">
			<Header2 />
			<div className={classes.wrapper}>
				{/* <Sidebar /> */}
				<div className={classes.contentWrapper}>
					<div className={classes.content}>
						<AppSuspense>{renderRoutes(routes)}</AppSuspense>
						<FuseMessage />
					</div>
				</div>
			</div>
		</div>
	);
}

export default React.memo(PrivateLayout);
