import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
// import InputAdornment from '@material-ui/core/InputAdornment';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { Grid, Button, TextField, Card, Menu, MenuItem, Snackbar, IconButton, Typography } from '@material-ui/core';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { deepPurple } from '@material-ui/core/colors';
import UserAdd from './UserAdd.js';
import UserEdit from './UserEdit.js';
import ConfirmDelete from './ConfirmDelete.js';
// import SearchIcon from '@material-ui/icons/Search';
// import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
// import * as actions from './store/actions';
import * as actions from './redux/userApi.js';
import Image from './images/usermgmt.png';
// import authService from 'app/services/authService';
// import { getOrganisationById } from '../Kyc/redux/organisationAPI';

const StyledTableCell = withStyles(theme => ({
	head: {
		backgroundColor: theme.palette.common.white,
		color: theme.palette.common.black,
		padding: '14px'
	},
	body: {
		fontSize: 14,
		color: '#707070',
		borderBottom: '1px solid #4A4A4A14',
		borderTop: '1px solid #4A4A4A14'
	}
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: '#F7F9FC'
		}
	}
}))(TableRow);

const useStyles = makeStyles(theme => ({
	purple: {
		color: theme.palette.getContrastText(deepPurple[500]),
		backgroundColor: deepPurple[500]
	},
	table: {
		minWidth: 700
	},
	searchWidth: {
		width: '100%'
	},
	icon: {
		padding: '6%'
	},
	root: {
		flexGrow: '1',
		display: 'flex',
		alignItems: 'center'
	},
	image: {
		padding: theme.spacing(2),
		alignItems: 'center',
		opacity: '90%',
		width: '200px'
	},
	usersmgmt: {
		textAlign: 'center',
		fontFamily: 'Nunito',
		fontSize: '30px'
	},
	usersmgmtnote: {
		textAlign: 'center',
		fontFamily: 'Nunito',
		fontSize: '15px',
		opacity: '41%'
	},
	usersmgmtnoauth: {
		textAlign: 'center',
		fontFamily: 'Nunito',
		fontSize: '30px',
		marginTop: '50px'
	},
	addUsers: {
		background: '#0062FF',
		padding: '14px 20px',
		width: '100%',
		height: 0,
		borderRadius: '4px',
		textAlign: 'center',
		color: '#fff',
		fontSize: '11px',
		textTransform: 'none',
		'&:hover': {
			backgroundColor: '#0062ff'
		}
	},
	addUsers1: {
		width: '90px',
		height: '30px',
		color: '#fafafb',
		fontSize: '9px',
		backgroundColor: '#0062ff',
		fontWeight: 'bold',
		borderRadius: '8px',
		marginRight: '10px',
		textTransform: 'none',
		'&:hover': {
			backgroundColor: '#0062ff'
		}
	},
	rowHeight: {
		height: '50px'
	},
	noAvatar: {
		width: "100%",
		height: "100%",
		background: "whitesmoke",
		display: "inline-block",
		borderRadius: "50%",
		'& span': {
			position: "absolute",
			top: "50%",
			color: "#fff",
			fontSize: "40px",
			left: "50%",
			transform: "translate(-50%, -50%)",
			textTransform: "uppercase",
		}
	}
}));
const StyledMenu = withStyles({
	paper: {
		border: '1px solid #d3d4d5'
	}
})(props => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'center'
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'center'
		}}
		{...props}
	/>
));

const StyledMenuItem = withStyles(theme => ({
	root: {
		'&:focus': {
			backgroundColor: theme.palette.primary.main,
			'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
				color: theme.palette.common.white
			}
		}
	}
}))(MenuItem);

const Settings = () => {
	const classes = useStyles();
	const [openForEdit, setOpenForEdit] = useState(false);
	const [editItems, setEditItems] = useState({});
	const [openForADD, setOpenForADD] = useState(false);
	const [snackbaropen, setSnackbaropen] = React.useState(false);
	const [snackbarmsg, setSnackbarmsg] = React.useState('');
	const [page, setPage] = useState(0);

	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [confirmDelete, setConfirmDelete] = useState({ openForDelete: false, title: '', Subtitle: '' });
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	const { users } = useSelector(state => state.usersStore);
	let usersArray = users && users.data ? users.data : [];
	const dispatch = useDispatch();
	const { user } = useSelector(state => state.usersStore);
	const [filter, setFilter] = useState({
		searchfilter: usersArray => {
			return usersArray;
		}
	});

	const capitalizeFirstLetter = (string) => {
		if (string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		}
	}

	const [organizationData, setOrganizationrData] = React.useState( () => {
		const localOrgData = JSON.parse(localStorage.getItem('organization'))
		console.log("localOrgData",localOrgData)
		return {
			legalName: localOrgData?.legalName,
			name: localOrgData?.name,
			place: localOrgData?.address?.place,
			state: localOrgData?.address?.state,
			email: localOrgData?.email,
			phone: localOrgData?.phone,
			image: localOrgData?.logo,
			gstin: localOrgData?.gstin?.gstinNumber
		}
	})

	const refreshList = () => {
		dispatch(actions.getUsersApi());
		setLoading(true);
	};

	const resetList = () => {
		dispatch(actions.userResetApi());
	};

	useEffect(() => {
		refreshList();
	}, []);

	useEffect(() => {
		if (user === {} || user === undefined) {
			return undefined;
		} else if (user && user.status === 'Success') {
			refreshList();
			setSnackbaropen(true);
			setSnackbarmsg(user.message);
			resetList();
			dispatch(actions.imageResetApi());
		} else if (user && user.status === 'Fail') {
			setSnackbaropen(true);
			setSnackbarmsg(user.message);
			resetList();
		}
	}, [user]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const pagination = () => {
		return filter.searchfilter(usersArray).slice(page * rowsPerPage, (page + 1) * rowsPerPage);
	};

	const handleClickAdd = useCallback(() => {
		setOpenForADD(true);
	}, [setOpenForADD]);

	const handleCloseAdd = useCallback(() => {
		setOpenForADD(false);
	}, [setOpenForADD]);

	const handleEdit = useCallback(
		item => {
			setOpenForEdit(true);
			setAnchorEl(null);
			setEditItems({
				phone: item.phone,
				firstName: item.firstName,
				lastName: item.lastName,
				role: item.role,
				email: item.email,
				profilePic: item.profilePic
			});
		},
		[setOpenForEdit, setEditItems]
	);

	const handleDelete = useCallback(
		email => {
			setAnchorEl(null);
			let obj = {};
			obj.email = email;
			dispatch(actions.deleteUserApi(obj));
			setConfirmDelete({ ...confirmDelete, openForDelete: false });
		},
		[confirmDelete, dispatch]
	);

	const handleSearch = event => {
		let target = event.target;
		setFilter({
			searchfilter: usersArray => {
				if (target.value === '') {
					return usersArray;
				} else {
					return usersArray.filter(
						col =>
							col.firstName.toLowerCase().includes(target.value) ||
							col.lastName.toLowerCase().includes(target.value) ||
							col.email.toLowerCase().includes(target.value) ||
							col.phone.toLowerCase().includes(target.value) ||
							col.role.toLowerCase().includes(target.value)
					);
				}
			}
		});
	};

	const handleCloseEdit = () => {
		setOpenForEdit(false);
	};

	const snackBarClose = () => {
		setSnackbaropen(false);
	};

	// const handleClick = (event) => {
	//   setAnchorEl(event.currentTarget);
	// };
	//
	// const handleClose = () => {
	//   setAnchorEl(null);
	// };
	return users && users.status === 'Fail' && users.message === 'Not Authorized to perform this action.' ? (
		<Grid container spacing={3} direction="column" justify="center" alignItems="center">
			<Grid item>
				<div className={classes.usersmgmtnoauth}>Not Authorized.Only Admin</div>
			</Grid>
		</Grid>
	) : usersArray && usersArray.length < 0 ? (
		<div className={classes.root}>
			<Grid container spacing={3} direction="column" justify="center" alignItems="center">
				<Grid item className={classes.image}>
					<img src={Image} alt={'User Management'} />
				</Grid>
				<Grid item>
					<div className={classes.usersmgmt}>User Management</div>
				</Grid>
				<Grid item>
					<div className={classes.usersmgmtnote}>Click on the button below to add users</div>
				</Grid>
				<Grid item>
					<Button className={classes.addUsers} onClick={handleClickAdd}>
						Add
					</Button>
					<UserAdd open={openForADD} title="Add Users" handleClose={handleCloseAdd} />
				</Grid>
			</Grid>
		</div>
	) : (
				<>
					<Snackbar
						anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
						open={snackbaropen}
						autoHideDuration={5000}
						onClose={snackBarClose}
						message={snackbarmsg}
						action={[
							<IconButton key="close" onClick={snackBarClose}>
								x
					</IconButton>
						]}
					/>
					{loading ? (
						<div>
							<UserEdit open={openForEdit} items={editItems} title="Edit Users" handleClose={handleCloseEdit} />
							<div style={{ width: "95%", margin: "0 auto" }}>
								<Grid container spacing={3} style={{marginTop:"3px"}}>
									<Grid item xs={4} lg={3} xl={3} md={3} style={{ textAlign: 'center' }}>
										<Card style={{ boxShadow: "0px 3px 6px #0000000F", textAlign: "center" }}>
											<Grid>
												<label>
													<span
														style={{
															position: 'relative',
															display: 'inline-block',
															cursor: 'pointer',
															width: '120px',
															height: '120px',
															marginTop: '35px'
														}}
													>
														{organizationData.image ? <img
															src={organizationData.image}
															alt=""
															style={{
																width: '100%',
																height: '100%',
																boxShadow: "0px 3px 6px #0000000F",
																borderRadius: '50%'
															}}
														/> : <p className={classes.noAvatar}>
																<span>{organizationData && organizationData.name ? organizationData.name.charAt(0) : ""}</span></p>}
													</span>
												</label>

												<Typography variant="h6" style={{ padding: '15px 0px 0px' }}>
													{organizationData && organizationData.legalName ? capitalizeFirstLetter(organizationData.legalName) : ''}
												</Typography>
												<Typography
													variant="h6"
													style={{ padding: '5px 0px 0px', color: 'gray', fontSize: '14px' }}
												>
													{organizationData && organizationData.state ? capitalizeFirstLetter(organizationData.state) + ", " + capitalizeFirstLetter(organizationData.place) : ''}
												</Typography>
											</Grid>
											<hr style={{ color: '#f2ebeb', margin: '14px 24px' }} />
											<Grid style={{ textAlign: "left", paddingLeft: "24px", paddingBottom: "10px" }}>
												<p style={{ color: "#0000004f", fontSize: "11px", marginBottom: "3px" }}>E-mail</p>
												<p>{organizationData && organizationData.email ? organizationData.email : ''}</p>
											</Grid>
											<Grid style={{ textAlign: "left", paddingLeft: "24px", paddingBottom: "10px" }}>
												<p style={{ color: "#0000004f", fontSize: "11px",marginBottom: "3px" }}>Phone number</p>
												<p>{organizationData && organizationData.phone ? organizationData.phone : ''}</p>
											</Grid>
											<Grid style={{ textAlign: "left", paddingLeft: "24px", paddingBottom: "25px" }}>
												<p style={{ color: "#0000004f", fontSize: "11px",marginBottom: "3px" }}>GSTIN number</p>
												<p>{organizationData && organizationData.gstin ? organizationData.gstin : ''}</p>
											</Grid>
										</Card>
									</Grid>
									<Grid item md={9} xl={9} lg={9} style={{paddingTop: "7px"}}>
										<div className="flex justify-between w-full pt-6 m-4"
											style={{
												padding: "16px",
												background: "#fff",
												margin: 0,
												marginTop: "6px",
												marginBottom: "3px",
												boxShadow: "1 0 0 #ccc",
												boxShadow: "0px 3px 6px #4A4A4A14"
											}}
										>
											<div style={{ display: "flex", alignItems: "center" }}>
												<h3 style={{ marginRight: "40px" }}>Users</h3>
												<TextField
													className={classes.searchWidth}
													variant="outlined"
													label="Search"
													size="small"
													onChange={handleSearch}
												/>
											</div>
											<div>
												<Button
													className={classes.addUsers}
													onClick={handleClickAdd}
												>
													Add
										</Button>
												<UserAdd open={openForADD} title="Add user" handleClose={handleCloseAdd} />
											</div>
										</div>
										<ConfirmDelete confirmDelete={confirmDelete} setConfirmDelete={setConfirmDelete} />
										<TableContainer component={Paper} style={{ boxShadow: "none" }}>
											<Table size="small">
												<TableHead>
													<TableRow>
														<StyledTableCell align="left" width="30%">
															Name
												</StyledTableCell>
														<StyledTableCell align="left" width="30%">
															Email
												</StyledTableCell>
														<StyledTableCell align="left" width="20%">
															Mobile
												</StyledTableCell>
														<StyledTableCell align="left" width="10%">
															Role
												</StyledTableCell>
														<StyledTableCell width="10%">Actions</StyledTableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{pagination().map(row => (
														<StyledTableRow className={classes.rowHeight} key={row.phone}>
															<StyledTableCell align="left" width="30%">
																{row.firstName}{' '}{row.lastName}
															</StyledTableCell>
															<StyledTableCell align="left" width="30%">
																{row.email}
															</StyledTableCell>
															<StyledTableCell align="left" width="20%">
																{row.phone}
															</StyledTableCell>
															<StyledTableCell align="left" width="10%">
																{row.role}
															</StyledTableCell>
															<StyledTableCell align="center" width="10%">
																<EditIcon
																	style={{ cursor: 'pointer', fontSize: "16px", color: "#0062FF", marginRight: "8px" }}
																	onClick={() => {
																		handleEdit(row);
																	}}
																/>
																<DeleteIcon
																	style={{ cursor: 'pointer', fontSize: "16px", color: "#be3728", }}
																	onClick={() => {
																		setConfirmDelete({
																			openForDelete: true,
																			title: 'Are you sure',
																			Subtitle: 'Do you want to DELETE user',
																			onConfirm: () => {
																				handleDelete(row.email);
																			}
																		});
																	}}
																/>
																{/*<IconButton
									aria-label="more"
									aria-controls="long-menu"
									aria-haspopup="true"
									onClick={handleClick}
								>
									<MoreVertIcon />
								</IconButton>
								<StyledMenu
									id="customized-menu"
									anchorEl={anchorEl}
									keepMounted
									open={Boolean(anchorEl)}
									onClose={handleClose}
								>
									<StyledMenuItem>
										<Button onClick={()=>{handleEdit(row);}}>Edit</Button>
									</StyledMenuItem>
									<StyledMenuItem>
									<Button onClick={()=>{handleDelete(row.email);}}>Delete</Button>

									</StyledMenuItem>

								</StyledMenu>*/}
															</StyledTableCell>
														</StyledTableRow>
													))}
												</TableBody>
											</Table>
										</TableContainer>
										<TablePagination
											style={{ marginTop: '15px' }}
											rowsPerPageOptions={[5, 10, 15]}
											component="div"
											count={usersArray.length}
											rowsPerPage={rowsPerPage}
											page={page}
											onChangePage={handleChangePage}
											onChangeRowsPerPage={handleChangeRowsPerPage}
										/>
									</Grid>
								</Grid>
							</div>
						</div>
					) : (
							<LinearProgress />
						)}
				</>
			);
};
export default Settings;
