/* eslint-disable camelcase */

import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
// import jwtDecode from 'jwt-decode';

import apiConfig from 'app/app-configs/apiConfig';
import API from 'app/middleware';

const CURRENT_URL = apiConfig.baseUrl;

class AuthService extends FuseUtils.EventEmitter {
	init() {
		this.setInterceptors();
		this.handleAuthentication();
	}

	setInterceptors = () => {
		axios.interceptors.response.use(
			response => {
				return response;
			},
			err => {
				return new Promise((resolve, reject) => {
					if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
						// if you ever get an unauthorized response, logout the user
						this.emit('onAutoLogout', 'Invalid access_token');
						this.setSession(null);
					}
					throw err;
				});
			}
		);
	};

	handleAuthentication = () => {
		const access_token = this.getAccessToken();
		if (!access_token) {
			this.emit('onNoAccessToken');
			return false;
		}

		if (this.isAuthTokenValid(access_token)) {
			// this.setSession(access_token);
			axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
			this.emit('onAutoLogin', true);
			return true;
		} else {
			this.setSession(null);
			this.emit('onAutoLogout', 'access_token expired');
			return false;
		}
	};
	getTokenData = () => {
		const access_token = this.getAccessToken();
		if (!access_token) {
			this.emit('onNoAccessToken');
			return {};
		} else {
			const tokenParsedData = this.decodeToken(access_token);
			// console.log(tokenParsedData)
			return tokenParsedData;
		}
	};

	signUp = async data => {
		const reqObj = {
			url: `${CURRENT_URL}/truce-ms/api/signup`,
			method: 'POST',
			data
		};

		const response = await API(reqObj, 'noToken');

		return response;
	};

	signInWithEmailAndPassword = async data => {
		const reqObj = {
			url: `${CURRENT_URL}/truce-ms/api/signin`,
			method: 'POST',
			data
		};

		const response = await API(reqObj, 'noToken');

		if (response.status === 200) {
			this.setSession(response.response);
			const tokenParsedData = this.decodeToken(response.response.access_token);
		}

		return response;
	};

	signInWithPhoneNumber = async data => {
		const reqObj = {
			url: `${CURRENT_URL}/registration-ms/api/otp-verify`,
			//	https://api-dev.krab.ai/v1/registration-ms/api/registration
			method: 'POST',
			data
		};

		const response = await API(reqObj, 'noToken');

		if (response.status === 200) {
			// console.log(response.response.data.tokenData, 'response');
			this.setSession(response.response.data.tokenData);
			const tokenParsedData = this.decodeToken(response.response.data.tokenData.access_token);
		}

		return response;
	};

	signInWithNewToken = async () => {
		const reqObj = {
			url: `${CURRENT_URL}/registration-ms/api/registration/token`,
			method: 'GET'
		};

		const response = await API(reqObj, 'noToken');
		console.log({response});
		if (response.status === 200) {
			this.setSession(response.response.data);
			const tokenParsedData = this.decodeToken(response.response.data.access_token);
		}

		return response;
	};

	signInWithToken = () => {
		return new Promise((resolve, reject) => {
			axios
				.get('/api/auth/access-token', {
					data: {
						access_token: this.getAccessToken()
					}
				})
				.then(response => {
					if (response.data.user) {
						this.setSession(response.data.access_token);
						// console.log("decodeToken", this.decodeToken(response.data.access_token))
						resolve(response.data.user);
					} else {
						this.logout();
						reject(new Error('Failed to login with token.'));
					}
				})
				.catch(error => {
					this.logout();
					reject(new Error('Failed to login with token.'));
				});
		});
	};

	signOut = () => {
		// this.setSession(null);
		this.clearStorage();
	};

	resetPassword = async data => {
		const reqObj = {
			url: `${CURRENT_URL}/truce-ms/api/reset-password`,
			method: 'PUT',
			data
		};

		const response = await API(reqObj, 'noToken');

		if (response.status === 200) {
			this.setSession(response.response.data);
			const tokenParsedData = this.decodeToken(response.response.data.access_token);
		}

		return response;
	};

	updateUserData = user => {
		return axios.post('/api/auth/user/update', {
			user
		});
	};

	clearStorage = () => {
		localStorage.clear();
		delete axios.defaults.headers.common.Authorization;
	};

	setSession = data => {
		if (data && data.access_token) {
			const list = ['access_token', 'expires_in', 'refresh_token', 'refresh_expires_in', 'token_type'];

			list.map(key => localStorage.setItem(`kc_${key}`, data[key]));

			axios.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;
		} else {
			// localStorage.removeItem('jwt_access_token');
			this.clearStorage();
		}
	};

	decodeToken = str => {
		//	console.log(str, 'strrrrrrrr');
		str = str.split('.')[1];

		str = str.replace(/-/g, '+');
		str = str.replace(/_/g, '/');
		switch (str.length % 4) {
			case 0:
				break;
			case 2:
				str += '==';
				break;
			case 3:
				str += '=';
				break;
			default:
				throw 'Invalid token';
		}

		str = decodeURIComponent(escape(atob(str)));

		str = JSON.parse(str);
		return str;
	};

	isAuthTokenValid = access_token => {
		if (!access_token) {
			return false;
		}
		// const decoded = jwtDecode(access_token);
		const decoded = this.decodeToken(access_token);
		const currentTime = Date.now() / 1000;
		if (decoded.exp < currentTime) {
			console.warn('access token expired');
			return false;
		}

		return true;
	};

	getAccessToken = () => {
		return window.localStorage.getItem('kc_access_token');
	};
}

const instance = new AuthService();

export default instance;
