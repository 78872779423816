import React from 'react';

const ProfilePageConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/profile',
			component: React.lazy(() => import('./Profile')),
			isPrivate: true
		},
		{
			path: '/profile-reg',
			component: React.lazy(() => import('./ProfileReg')),
			isPrivate: true
		}
	]
};

export default ProfilePageConfig;
