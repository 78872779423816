import axios from 'axios';
import apiConfig from 'app/app-configs/apiConfig';

export const GET_ORGANISATION = '[GET ORGANISATION] GET ORGANISATION';
export const GET_ORGANISATION_ERROR = '[GET ORGANISATION ERROR ] GET ORGANISATION ERROR';
export const ORGANISATION_NULL = '[ORGANISATION ] ORGANISATION NULL';

export function getOrganisationNull() {
	return dispatch => {
		dispatch({
			type: ORGANISATION_NULL,
			payload: {}
		});
	};
}
export function getOrganisationById(id) {
	const request = axios({
		method: 'GET',
		url: `https://api-dev.krab.ai/v1/organizations-ms/api/organization/${id}`
	});
	return dispatch => {
		request
			.then(response => {
				dispatch({
					type: GET_ORGANISATION,
					payload: {
						response: response.data,
						statusCode: response.status
					}
				});
			})
			.catch(error => {
				dispatch({
					type: GET_ORGANISATION_ERROR,
					payload: {
						response: error && error.response && error.response.data,
						statusCode: error && error.response && error.response.status
					}
				});
			});
	};
}
// export const CREATE_ORGANISATION = '[CREATE ORGANISATION] CREATE ORGANISATION';
// export const CREATE_ORGANISATION_ERROR = '[CREATE ORGANISATION ERROR] CREATE ORGANISATION ERROR';
// export function createOrganisation(postData) {
// 	const request = axios({
// 		method: 'POST',
// 		url: apiConfig.baseUrl + '/organizations-ms/api/organization',
// 		data: postData,
// 		headers: {
// 			Authorization: keycloakService.getAuthToken()
// 		}
// 	});
// 	return dispatch => {
// 		request
// 			.then(response => {
// 				dispatch({
// 					type: CREATE_ORGANISATION,
// 					payload: {
// 						response: response.data,
// 						statusCode: response.status
// 					}
// 				});
// 			})
// 			.catch(error => {
// 				dispatch({
// 					type: CREATE_ORGANISATION_ERROR,
// 					payload: {
// 						response: error.response.data,
// 						statusCode: error.response.status
// 					}
// 				});
// 			});
// 	};
// }

// export const UPDATE_ORGANISATION = '[UPDATE ORGANISATION] UPDATE ORGANISATION';
// export const UPDATE_ORGANISATION_ERROR = '[UPDATE ORGANISATOIN ERROR] UPDATE ORGANISATION ERROR';

// export function updateOrganisation(id, data) {
// 	const request = axios({
// 		method: 'PUT',
// 		url: apiConfig.baseUrl + '/organizations-ms/api/organization/' + id,
// 		data: data,
// 		headers: {
// 			Authorization: keycloakService.getAuthToken()
// 		}
// 	});
// 	return dispatch => {
// 		request
// 			.then(response => {
// 				dispatch({
// 					type: UPDATE_ORGANISATION,
// 					payload: {
// 						response: response.data,
// 						statusCode: response.status
// 					}
// 				});
// 			})
// 			.catch(error => {
// 				dispatch({
// 					type: UPDATE_ORGANISATION_ERROR,
// 					payload: {
// 						response: error.response.data,
// 						statusCode: error.response.status
// 					}
// 				});
// 			});
// 	};
// }

// export const DELETE_ORGANISATION = '[DELETE ORGANISATION] DELETE ORGANISATION';
// export const DELETE_ORGANISATION_ERROR = '[DELETE ORGANISATION ERROR] DELETE ORGANISATION ERROR';
//
// export function deleteOrganisationById(data) {
// 	const request = axios({
// 		method: 'DELETE',
// 		url: apiConfig.baseUrl + '/organizations-ms/api/organization/' + data.id,
// 		data: { status: data.status, updatedBy: data.updatedBy },
// 		headers: {
// 			Authorization: keycloakService.getAuthToken()
// 		}
// 	});
// 	return dispatch => {
// 		request
// 			.then(response => {
// 				dispatch(
// 					showMessage({
// 						message: 'Organisation deleted successfullly',
// 						autoHideDuration: 6000, //ms
// 						anchorOrigin: {
// 							vertical: 'top',
// 							horizontal: 'center'
// 						},
// 						variant: 'success'
// 					})
// 				);
// 				dispatch({
// 					type: DELETE_ORGANISATION,
// 					payload: {
// 						response: response.data,
// 						statusCode: response.status
// 					}
// 				});
// 			})
// 			.catch(error => {
// 				dispatch({
// 					type: DELETE_ORGANISATION_ERROR,
// 					payload: {
// 						response: error.response.data,
// 						statusCode: error.response.status
// 					}
// 				});
// 			});
// 	};
// }
//
export const UPLOAD_ORGANISATION = '[ORGANISATION APP] UPLOAD ORGANISATION';
export const UPLOAD_ORGANISATION_ERROR = '[ORGANISATION APP] UPLOAD ORGANISATION ERROR';

export function uploadOrganisation(file, id, docType) {
	const request = axios({
		method: 'POST',
		url: `${apiConfig.baseUrl}/organizations-ms/api/organization/upload/${id}/${docType}`,
		// url: 'http://localhost:5001/V1/organizations-ms/api/organization/upload/' + id + '/' + docType,
		data: file,
		headers: {
			Authorization:
				'bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJoSlRXenZIWXNHQ0pNRjdRNDRZZXV2QXl4Nl8yOHJCMUZpQzNVSDdwVjFrIn0.eyJleHAiOjE2MDI4NjM3NDIsImlhdCI6MTYwMjc3NzM0MiwianRpIjoiZmEwMTkzNTUtMWE2YS00NmRkLWE5ZjUtNzM2YzhmYjg2YjFlIiwiaXNzIjoiaHR0cHM6Ly9hcGktZGV2LmtyYWIuYWkvYXV0aC9yZWFsbXMvZGV2ZWxvcG1lbnQiLCJhdWQiOiJhY2NvdW50Iiwic3ViIjoiNjU4MjNiNTAtOWE3MS00NDRmLTk3ZWQtOTgzMjFmMjViNGUwIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiZGV2ZWxvcG1lbnQiLCJzZXNzaW9uX3N0YXRlIjoiNTcxOTEzMmEtNDZlOS00ZDM5LTk4ZGYtMzA5YjZkMThhNTJkIiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwOi8vYXBwLWRldi5maW5vdm8uaW4iLCJodHRwczovL2xvY2FsaG9zdDozMDAwIiwiaHR0cHM6Ly9hcHAtZGV2LmZpbm92by5pbiIsImh0dHA6Ly9sb2NhbGhvc3Q6MzAwMCJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJ1bWFfYXV0aG9yaXphdGlvbiJdfSwicmVzb3VyY2VfYWNjZXNzIjp7ImFjY291bnQiOnsicm9sZXMiOlsibWFuYWdlLWFjY291bnQiLCJtYW5hZ2UtYWNjb3VudC1saW5rcyIsInZpZXctcHJvZmlsZSJdfX0sInNjb3BlIjoicHJvZmlsZSBlbWFpbCIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwib3JnYW5pemF0aW9uX2lkIjoiT1JHQU5JWkFUSU9OXzEiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJ0ZXN0dXNlciIsImdyb3VwIjpbXX0.H3D9pz6NIrlGBuFm1uBiA_BHGeq6p9zGo32zGvJb438Ou4di1DO3dglvq86PJQY8UcL7W0cFFSd6-pPqtJtGRRpXrF6x8Kc_x3amrbD3kAONCgMOEuV26hH97avh4AcBcBhBqNXddpulADuURqagPSsRHBFalbwfqQFTdjYwxFCp81i8nZZgDsc5zdxIuZX_KRwyDVQV2od0zom-g-zy2uWM0HWap0wQs6qD0FKtcjO3ZiTMzDzFBcOk7Jwt3jTbeuWxRuAcBkSPSxdamXegLKptexbsib6vpA5ftPwQLClI47utVKZUjwl6YnIFhzrsMgvUUF0nRR7w2K38pSLViQ'
		}
	});
	return dispatch => {
		request
			.then(response => {
				// dispatch();
				// showMessage({
				// 	message: 'FIle uploaded successfully',
				// 	autoHideDuration: 2000, //ms
				// 	anchorOrigin: {
				// 		vertical: 'top',
				// 		horizontal: 'center'
				// 	},
				// 	variant: 'success'
				// });
				dispatch({
					type: UPLOAD_ORGANISATION,
					payload: {
						response: response.data,
						statusCode: response.status
					}
				});
			})
			.catch(error => {
				if (!error.response) {
					dispatch({
						type: UPLOAD_ORGANISATION_ERROR,
						payload: {
							response: 'Network error. Please check your networkconnection',
							statusCode: 'Network error. Please check your networkconnection'
						}
					});
				} else {
					dispatch({
						type: UPLOAD_ORGANISATION_ERROR,
						payload: {
							response: error.response.data,
							statusCode: error.response.status
						}
					});
				}
			});
	};
}

// organization kyc
export const ORGANISATION_KYC = '[ORGANISATION KYC] ORGANISATION KYC';
export const ORGANISATION_KYC_ERROR = '[ORGANISATOIN KYC ERROR] ORGANISATION ERROR KYC';

export function organisationKyc(id, data) {
	const request = axios({
		method: 'PUT',
		url: `https://api-dev.krab.ai/v1/organizations-ms/api/organization/${id}`,
		data
	});
	return dispatch => {
		request
			.then(response => {
				// console.log('response', response);
				dispatch({
					type: ORGANISATION_KYC,
					payload: {
						response: response.data,
						statusCode: response.status
					}
				});
			})
			.catch(error => {
				if (!error.response) {
					dispatch({
						type: ORGANISATION_KYC_ERROR,
						payload: {
							response: 'Network error. Please check your networkconnection',
							statusCode: 'Network error. Please check your networkconnection'
						}
					});
				} else {
					dispatch({
						type: ORGANISATION_KYC_ERROR,
						payload: {
							response: error.response.data,
							statusCode: error.response.status
						}
					});
				}
			});
	};
}

// export const EWAYBILL_LINK = '[LINK EWAYBILL] LINK EWAYBILL';
// export const EWAYBILL_LINK_ERROR = '[LINK EWAYBILL ERROR] LINK EWAYBILL ERROR';

// export function linkEwayBill(postData) {
// 	const request = axios({
// 		method: 'POST',
// 		url: `https://api-dev.krab.ai/v1/ewaybills-ms/api/link-ewb`,
// 		data: postData,
// 		headers: {
// 			// Authorization: keycloakService.getAuthToken()
// 			Authorization:
// 				'bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJoSlRXenZIWXNHQ0pNRjdRNDRZZXV2QXl4Nl8yOHJCMUZpQzNVSDdwVjFrIn0.eyJleHAiOjE2MDI4NjM3NDIsImlhdCI6MTYwMjc3NzM0MiwianRpIjoiZmEwMTkzNTUtMWE2YS00NmRkLWE5ZjUtNzM2YzhmYjg2YjFlIiwiaXNzIjoiaHR0cHM6Ly9hcGktZGV2LmtyYWIuYWkvYXV0aC9yZWFsbXMvZGV2ZWxvcG1lbnQiLCJhdWQiOiJhY2NvdW50Iiwic3ViIjoiNjU4MjNiNTAtOWE3MS00NDRmLTk3ZWQtOTgzMjFmMjViNGUwIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiZGV2ZWxvcG1lbnQiLCJzZXNzaW9uX3N0YXRlIjoiNTcxOTEzMmEtNDZlOS00ZDM5LTk4ZGYtMzA5YjZkMThhNTJkIiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwOi8vYXBwLWRldi5maW5vdm8uaW4iLCJodHRwczovL2xvY2FsaG9zdDozMDAwIiwiaHR0cHM6Ly9hcHAtZGV2LmZpbm92by5pbiIsImh0dHA6Ly9sb2NhbGhvc3Q6MzAwMCJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJ1bWFfYXV0aG9yaXphdGlvbiJdfSwicmVzb3VyY2VfYWNjZXNzIjp7ImFjY291bnQiOnsicm9sZXMiOlsibWFuYWdlLWFjY291bnQiLCJtYW5hZ2UtYWNjb3VudC1saW5rcyIsInZpZXctcHJvZmlsZSJdfX0sInNjb3BlIjoicHJvZmlsZSBlbWFpbCIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwib3JnYW5pemF0aW9uX2lkIjoiT1JHQU5JWkFUSU9OXzEiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJ0ZXN0dXNlciIsImdyb3VwIjpbXX0.H3D9pz6NIrlGBuFm1uBiA_BHGeq6p9zGo32zGvJb438Ou4di1DO3dglvq86PJQY8UcL7W0cFFSd6-pPqtJtGRRpXrF6x8Kc_x3amrbD3kAONCgMOEuV26hH97avh4AcBcBhBqNXddpulADuURqagPSsRHBFalbwfqQFTdjYwxFCp81i8nZZgDsc5zdxIuZX_KRwyDVQV2od0zom-g-zy2uWM0HWap0wQs6qD0FKtcjO3ZiTMzDzFBcOk7Jwt3jTbeuWxRuAcBkSPSxdamXegLKptexbsib6vpA5ftPwQLClI47utVKZUjwl6YnIFhzrsMgvUUF0nRR7w2K38pSLViQ'
// 		}
// 	});
// 	return dispatch => {
// 		request
// 			.then(response => {
// 				dispatch({
// 					type: EWAYBILL_LINK,
// 					payload: {
// 						response: response.data,
// 						statusCode: response.status
// 					}
// 				});
// 			})
// 			.catch(error => {
// 				dispatch({
// 					type: EWAYBILL_LINK_ERROR,
// 					payload: {
// 						response: error.response.data,
// 						statusCode: error.response.status
// 					}
// 				});
// 			});
// 	};
// }

export const GET_FILE_URL = '[CARRIER APP] GET_FILE_URL CARRIER';
export const GET_FILE_URL_ERROR = '[CARRIER APP] GET_FILE_URL CARRIER ERROR';

export function getFileUrl(postData) {
	const request = axios({
		method: 'POST',
		url: `${apiConfig.baseUrl}/aws-ms/api/s3/get-signed-url`,
		data: postData,
		headers: {
			// Authorization: keycloakService.getAuthToken()
			Authorization:
				'bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJoSlRXenZIWXNHQ0pNRjdRNDRZZXV2QXl4Nl8yOHJCMUZpQzNVSDdwVjFrIn0.eyJleHAiOjE2MDI4NjM3NDIsImlhdCI6MTYwMjc3NzM0MiwianRpIjoiZmEwMTkzNTUtMWE2YS00NmRkLWE5ZjUtNzM2YzhmYjg2YjFlIiwiaXNzIjoiaHR0cHM6Ly9hcGktZGV2LmtyYWIuYWkvYXV0aC9yZWFsbXMvZGV2ZWxvcG1lbnQiLCJhdWQiOiJhY2NvdW50Iiwic3ViIjoiNjU4MjNiNTAtOWE3MS00NDRmLTk3ZWQtOTgzMjFmMjViNGUwIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiZGV2ZWxvcG1lbnQiLCJzZXNzaW9uX3N0YXRlIjoiNTcxOTEzMmEtNDZlOS00ZDM5LTk4ZGYtMzA5YjZkMThhNTJkIiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwOi8vYXBwLWRldi5maW5vdm8uaW4iLCJodHRwczovL2xvY2FsaG9zdDozMDAwIiwiaHR0cHM6Ly9hcHAtZGV2LmZpbm92by5pbiIsImh0dHA6Ly9sb2NhbGhvc3Q6MzAwMCJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJ1bWFfYXV0aG9yaXphdGlvbiJdfSwicmVzb3VyY2VfYWNjZXNzIjp7ImFjY291bnQiOnsicm9sZXMiOlsibWFuYWdlLWFjY291bnQiLCJtYW5hZ2UtYWNjb3VudC1saW5rcyIsInZpZXctcHJvZmlsZSJdfX0sInNjb3BlIjoicHJvZmlsZSBlbWFpbCIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwib3JnYW5pemF0aW9uX2lkIjoiT1JHQU5JWkFUSU9OXzEiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJ0ZXN0dXNlciIsImdyb3VwIjpbXX0.H3D9pz6NIrlGBuFm1uBiA_BHGeq6p9zGo32zGvJb438Ou4di1DO3dglvq86PJQY8UcL7W0cFFSd6-pPqtJtGRRpXrF6x8Kc_x3amrbD3kAONCgMOEuV26hH97avh4AcBcBhBqNXddpulADuURqagPSsRHBFalbwfqQFTdjYwxFCp81i8nZZgDsc5zdxIuZX_KRwyDVQV2od0zom-g-zy2uWM0HWap0wQs6qD0FKtcjO3ZiTMzDzFBcOk7Jwt3jTbeuWxRuAcBkSPSxdamXegLKptexbsib6vpA5ftPwQLClI47utVKZUjwl6YnIFhzrsMgvUUF0nRR7w2K38pSLViQ'
		}
	});
	return dispatch => {
		request
			.then(response => {
				dispatch({
					type: GET_FILE_URL,
					payload: {
						response: response.data,
						statusCode: response.status
					}
				});
			})
			.catch(error => {
				if (!error.response) {
					// showMessage({
					//     message: 'Network error. Please check your networkconnection',
					//     autoHideDuration: 3000, // ms
					//     anchorOrigin: {
					//         vertical: 'top',
					//         horizontal: 'center'
					//     },
					//     variant: 'info'
					// })
					dispatch({
						type: GET_FILE_URL_ERROR,
						payload: {
							response: 'Network error. Please check your networkconnection',
							statusCode: 'Network error. Please check your networkconnection'
						}
					});
				} else {
					dispatch({
						type: GET_FILE_URL_ERROR,
						payload: {
							response: error.response.data,
							statusCode: error.response.status
						}
					});
				}
			});
	};
}
