import AppContext from 'app/AppContext';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchRoutes } from 'react-router-config';
import { withRouter } from 'react-router-dom';
import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
// import Utils from './Utils';
// import auth0Service from 'app/services/auth0Service';
// import Keycloak from 'keycloak-js';
// import {AUTH_CONFIG_KEYCLOAK} from 'app/services/keycloakService/keycloakServiceConfig';
import keycloakService from 'app/services/keycloakService';
import authService from 'app/services/authService';
import * as LayoutActions from 'app/app-layout/store/layoutSlice/actions';
import { bindActionCreators } from '@reduxjs/toolkit';

class Authorization extends Component {
	constructor(props, context) {
		super(props);
		// console.log(props)
		const { routes } = context;
		this.state = {
			isPrivateRoute: false,
			accessGranted: null,
			path: '/',
			loading: true,
			routes
		};
	}

	componentDidMount() {
		// console.log('componentDidMount', this.props.layout);

		if (this.state.isPrivateRoute) {
			if (this.state.accessGranted) {
				this.setState({ loading: false });
			} else {
				// this.keycloakCheck();
				this.authCheck();
			}
		} else {
			this.authCheck();
			this.setState({ accessGranted: true });
			this.setState({ loading: false });
		}

	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextState.loading !== this.state.loading;
	}

	componentDidUpdate() {
		//		console.log('componentDidUpdate', this.state.isPrivateRoute, this.state.accessGranted);

		if (this.state.isPrivateRoute) {
			if (this.state.accessGranted) {
				this.setState({ loading: false });
			} else {
				// this.keycloakCheck();
				this.authCheck();
			}
		} else {
			this.setState({ accessGranted: true });
			this.setState({ loading: false });
		}
	}

	static getDerivedStateFromProps(props, state) {
		const { location } = props; // userRole,layout
		const { pathname } = location;

		const matched = matchRoutes(state.routes, pathname)[0];
		// let accessGranted = matched ? Utils.hasPermission(matched.route.auth, userRole) : true;
		let isPrivateRoute = matched && matched.route.isPrivate ? matched.route.isPrivate : false;
		let path = matched ? matched.route.path : '/';
		if (matched.route.isPrivate === undefined) {
			isPrivateRoute = false;
		}
		// let isAccessGranted = true;

		if (isPrivateRoute) {
			let payload = {
				layout: 'private',
				header: true,
				sidebar: true
			};
			props.getLayout(payload);

			// localStorage.setItem('isPrivateLayout', true);
			// isAccessGranted = false;
			// accessGranted = false;
		} else {
			let payload = {
				layout: 'public',
				header: true,
				sidebar: true
			};
			props.getLayout(payload);
			// localStorage.setItem('isPrivateLayout', false);
		}
		// console.log("getDerivedStateFromProps",isPrivateRoute,matched.route)
		return {
			// accessGranted: accessGranted,
			// isAccessGranted: isAccessGranted,
			isPrivateRoute: isPrivateRoute,
			path: path
		};
	}

	authCheck = () => {
		// console.log('authService.handleAuthentication()', authService.handleAuthentication());

		if (authService.handleAuthentication()) {
			this.setState({ accessGranted: true });
			this.setState({ loading: false });
			this.goToWelcome();
		} else {
			this.setState({ loading: false });
			this.setState({ accessGranted: false });
			this.redirectRoute();
		}
	};

	keycloakCheck = () => {
		// let keycloakService = Keycloak(AUTH_CONFIG_KEYCLOAK);
		// const onLoad = process.env.REACT_APP_ENV === 'dev' ? 'check-sso' : 'login-required';
		//	console.log('keycloakCheck', keycloakService);
		let options = { onLoad: 'login-required', promiseType: 'native' };
		// options =  {onLoad: 'check-sso',silentCheckSsoRedirectUri: window.location.origin + this.state.path}

		keycloakService.init(options).success(authenticated => {
			if (authenticated) {
				//	console.log('kc if authenticated ', keycloakService);
				this.setState({ accessGranted: true });
				this.setState({ loading: false });
				// this.setState({ waitAuthCheck: true });
				// this.props.setKeycloakData(kc);
				// this.props.setUserDataKeycloak(kc);
				// this.props.keycloakLogout(kc)
				// this.props.showMessage({message: 'Logged in with keycloak'});
			} else {
				//	console.log('kc else ', authenticated, keycloakService);
				this.setState({ loading: false });
				this.setState({ accessGranted: false });
				// kc.login();
				this.redirectRoute();
			}
		});
	};

	goToWelcome() {
		const { location, history } = this.props;
		const { pathname,state } = location;
		// console.log('state.redirectUrl',this.props,pathname)
		let redirectUrl = pathname;
		if(pathname === "/"){
			redirectUrl= '/welcome';
		}
		history.push({
			pathname: pathname
		});
	}

	redirectRoute() {
		const { location, userRole, history } = this.props;
		const { pathname, state } = location;
		const redirectUrl = state && state.redirectUrl ? state.redirectUrl : '/';

		/*
				User is guest
				Redirect to Login Page
				*/
		if (!userRole || userRole.length === 0) {
			this.setState({ loading: false });
			history.push({
				pathname: '/',
				state: { redirectUrl: pathname }
			});
		} else {
			/*
				User is member
				User must be on unAuthorized page or just logged in
				Redirect to dashboard or redirectUrl
				*/
			history.push({
				pathname: redirectUrl
			});
		}
	}

	render() {
		// console.info('Fuse Authorization rendered', accessGranted);
		// console.log("render",this.state.loading, this.state.accessGranted)
		return this.state.loading ? <FuseSplashScreen /> : <>{this.props.children}</>;
	}
}

function mapStateToProps({ auth, settings }) {
	return {
		userRole: auth.user.role,
		layout: settings.layout
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			getLayout: LayoutActions.getLayout
		},
		dispatch
	);
}

Authorization.contextType = AppContext;

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Authorization)
);
