import { createSlice } from '@reduxjs/toolkit';

const tripsSlice = createSlice({
	name: 'trirps',
	initialState: {
		customers: {},
		customer: {},
		locations: {},
		location: {},
		lrData: {},
		lrDataUpdate: {},
		error: {},
		consignmentType: {},
		consignmentDelete: {},
		createLr: {},
		loading: false,
		supplierBillLine: {},
		deleteSupplierBillLine: {},
		supplierBillPayment: {},
		deleteSupplierBillPayment: {},
		generateLrNumber: {},
		getEwaBillNum: {},
		createTrip: {},
		completedTrips: {},
		currentTrips: {},
		searchTrip: {},
		getUser: {}
	},
	reducers: {
		getCustomers: (state, action) => {
			const { hits } = action.payload.response;
			state.customers = hits;
		},
		createCustomer: (state, action) => {
			state.customer = action.payload.response;
		},
		getLocations: (state, action) => {
			const { hits } = action.payload.response;
			state.locations = hits;
		},
		createLocation: (state, action) => {
			state.location = action.payload.response;
		},
		errorResponse: (state, action) => {
			state.error = action.payload;
		},
		getLrData: (state, action) => {
			state.lrData = action.payload;
		},
		updateLr: (state, action) => {
			state.lrDataUpdate = action.payload;
		},
		consignmentType: (state, action) => {
			state.consignmentType = action.payload;
		},
		consignmentDelete: (state, action) => {
			state.consignmentDelete = action.payload;
		},
		tripsLoader: (state, action) => {
			state.loading = action.payload;
		},
		createLr: (state, action) => {
			state.createLr = action.payload;
		},
		addSupplierBillLine: (state, action) => {
			state.supplierBillLine = action.payload
		},
		addSupplierBillPayment: (state, action) => {
			state.supplierBillPayment = action.payload
		},
		deleteSupplierBillLine: (state, action) => {
			state.deleteSupplierBillLine = action.payload
		},
		deleteSupplierBillPayment: (state, action) => {
			state.deleteSupplierBillPayment = action.payload
		},
		generateLrNumber: (state, action) => {
			state.generateLrNumber = action.payload
		},
		getEwaBillNum: (state, action) => {
			state.getEwaBillNum = action.payload
		},
		createTrip: (state, action) => {
			state.createTrip = action.payload
		},
		completedTrips: (state, action) => {
			state.completedTrips = action.payload
		},
		currentTrips: (state, action) => {
			state.currentTrips = action.payload
		},
		searchTrip: (state, action) => {
			state.searchTrip = action.payload
		},
		getUser: (state, action) => {
			state.getUser = action.payload
		}
	}
});

export default tripsSlice.reducer;

export const {
	getCustomers,
	createCustomer,
	errorResponse,
	getLocations,
	createLocation,
	getLrData,
	updateLr,
	consignmentType,
	consignmentDelete,
	createLr,
	tripsLoader,
	addSupplierBillLine, deleteSupplierBillLine,
	addSupplierBillPayment, deleteSupplierBillPayment, 
	generateLrNumber, getEwaBillNum, createTrip, 
	completedTrips, currentTrips, searchTrip,
	getUser
} = tripsSlice.actions;
