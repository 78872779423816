import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import VehicleImage from './images/Logistics-rafiki.png';
import HandWaveImage from './images/hand-wave.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: "1",
    display: "flex",
    alignItems: "center",
    fontFamily: 'Nunito',
    textAlign: 'center'
  },
  image: {
	  padding: theme.spacing(2),
	  width: '240px'
  },
  welcomeIcon: {
    width: '46px',
    padding: "0px 8px 8px 0px"
  },
  welcome: {
    fontSize: '30px',
    fontWeight: "700",
    margin: "10px 5px"
  },
  welcomeNote: {
	  fontSize: '15px',
	  opacity: '41%'
  },
  createTrip: {
    padding: "8px 22px",
    color: '#fafafb',
	  fontSize: '14px',
	  backgroundColor: '#0062ff',
	  borderRadius: '6px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#0062ff'
    }
  }
}));


function WelcomePage(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
	    <Grid container spacing={3} direction="column" justify="center" alignItems="center">
        <Grid item>
			    <img src={VehicleImage} alt={"Logistics Image"} style={{width: "80%", margin: "0 auto"}} />
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center" >
          <img src={HandWaveImage} alt={"HandWave Image"} className={classes.welcomeIcon} />
          <div className={classes.welcome}>Welcome</div>
        </Grid>
        <Grid item className={classes.welcomeNote}>
          <div>Hey Admin, so glad you’re here!</div>
        </Grid>
      </Grid>
    </div>
  );
}

export default withRouter(WelcomePage);
