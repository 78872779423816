import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MixpanelConsumer } from 'react-mixpanel';

import Login from './login';
import SignUp from './signUp';

import InputScreen from './inputScreen';
import ResetPassword from './resetPassword';
import OtpScreen from './otpScreen';

const useStyles = makeStyles(theme => ({
	main: { width: 450, padding: 20 }
}));

function Auth(props) {
	const classes = useStyles();

	const [currentScreen, setScreen] = React.useState(props.screen ? props.screen : 'InputScreen');
	const [data, setData] = React.useState({});

	const list = { Login, SignUp, InputScreen, ResetPassword, OtpScreen };
	const CurrentScreen = list[currentScreen];

	const handleClose = () => {
		props.reset();
		props.onClose();
	};

	return (
		<div className={classes.main}>
			<MixpanelConsumer>
				{mixpanel => (
					<CurrentScreen
						mixpanel={mixpanel}
						setScreen={val => setScreen(val)}
						setData={obj => setData(obj)}
						data={data}
						onClose={() => handleClose()}
					/>
				)}
			</MixpanelConsumer>
		</div>
	);
}

export default Auth;
