import React from 'react';

const OrgKycConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/org-kyc',
			component: React.lazy(() => import('./OrganizationKycIndex')),
			isPrivate: true
		},
		{
			path: '/org-kyc/unverified',
			component: React.lazy(() => import('./KycUnverifiedList')),
			isPrivate: true
		},
		{
			path: '/org-kyc/verified',
			component: React.lazy(() => import('./KycVerifiedList')),
			isPrivate: true
		}
	]
};

export default OrgKycConfig;
