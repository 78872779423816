/* eslint-disable camelcase */

import React from 'react';
import { useDispatch } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import authService from 'app/services/authService';

// import * as LayoutActions from 'app/app-layout/store/layoutSlice/actions';
import * as Actions from './store/loginSlice/actions';

const useStyles = makeStyles(theme => ({
	main: {
		fontFamily: 'Nunito',
		height: '100%',
		display: 'flex',
		flex: 1,
		flexDirection: 'column'
	},
	title: { fontSize: 35, color: 'black', textAlign: 'center' },
	sub: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' },
	icon: { fontSize: 32, color: 'black' },
	subHead: { fontSize: 15, color: '#AFB3B0' },
	subDiv: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
	form: { display: 'flex' },
	input: { marginTop: 10, width: '70%' },
	register: {
		width: '70%',
		color: 'white',
		fontSize: 15,
		fontWeight: 'bold',
		padding: 8,
		textTransform: 'none',
		background: '#0062FF',
		marginTop: 30,
		borderRadius: 7,
		'&:hover': {
			background: '#0062FF'
		}
	},
	footer: { color: 'black', marginBottom: 10, textAlign: 'center' },
	signin: { color: '#0062FF', fontSize: 18, fontFamily: 'Poppins', textTransform: 'none', paddingTop: 0 },
	underline: { borderTop: '2px solid #0062FD', width: 40, marginTop: 10, marginBottom: 10 }
}));

function SignUp(props) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [firstName, setFirstName] = React.useState('');
	const [lastName, setLastName] = React.useState('');
	const [legalName, setLegalName] = React.useState('');
	const [phone, setPhone] = React.useState('');
	const [email, setEmail] = React.useState('');

	const [loader, setLoader] = React.useState(false);
	const [response, setResponse] = React.useState(null);

	const handleClose = () => {
		setLegalName('');
		setPhone('');
		setEmail('');

		props.onClose();
	};

	const handleSignUp = async () => {
		const payload = { firstName, lastName, legalName, phone, email, name: legalName };

		// const layout = {
		// 	layout: 'private',
		// 	header: true,
		// 	sidebar: false
		// };

		setLoader(true);
		setResponse(null);

		const res = await authService.signUp(payload);
		await dispatch(Actions.SignUp(res));

		setLoader(false);
		setResponse(res);

		if (res.status === 200) {
			const data = {
				phone: res.response.data.phone,
				email: res.response.data.email
			};

			props.setData(data);

			await dispatch(Actions.RequestOTP({ search: data.email }));
			props.setScreen('OtpScreen');
		}
	};

	const res = (response && response?.response) || {};

	let errMsg = res?.data?.error_description || 'Sign Up Failed';

	if (res.message) errMsg = Array.isArray(res.message) ? res.message[0] : res.message;

	return (
		<div className={classes.main}>
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<IconButton onClick={() => handleClose()} style={{ padding: 0 }}>
					<ClearRoundedIcon className={classes.icon} />
				</IconButton>
			</div>

			<div className={classes.title}>Sign Up</div>

			<center>
				<div className={classes.underline} />
			</center>

			<center className={classes.subHead}>For quick and easy LR generation</center>

			<div className={classes.subDiv}>
				<TextField
					className={classes.input}
					label="Your Company Name"
					value={legalName}
					inputProps={{ style: { fontSize: 15 } }}
					InputLabelProps={{ style: { fontSize: 15 } }}
					onChange={e => setLegalName(e.target.value)}
				/>

				<TextField
					className={classes.input}
					label="First Name"
					value={firstName}
					inputProps={{ style: { fontSize: 15 } }}
					InputLabelProps={{ style: { fontSize: 15 } }}
					onChange={e => setFirstName(e.target.value)}
				/>

				<TextField
					className={classes.input}
					label="Last Name"
					value={lastName}
					inputProps={{ style: { fontSize: 15 } }}
					InputLabelProps={{ style: { fontSize: 15 } }}
					onChange={e => setLastName(e.target.value)}
				/>

				<TextField
					className={classes.input}
					label="Phone Number"
					value={phone}
					inputProps={{ maxLength: '10', style: { fontSize: 15 } }}
					InputLabelProps={{ style: { fontSize: 15 } }}
					onChange={e => setPhone(e.target.value)}
				/>

				<TextField
					className={classes.input}
					label="Work Email"
					value={email}
					inputProps={{ style: { fontSize: 15 } }}
					InputLabelProps={{ style: { fontSize: 15 } }}
					onChange={e => setEmail(e.target.value)}
				/>

				<Button variant="contained" className={classes.register} onClick={() => handleSignUp()}>
					Sign Up{' '}
				</Button>

				<div style={{ marginTop: 20, textAlign: 'center', fontSize: 20 }}>
					{response && response.status !== 200 ? <div style={{ color: 'red' }}>{errMsg}</div> : null}
					{loader ? <div style={{ color: '#0062FF' }}>Signing Up ... Please Wait ... </div> : null}
				</div>
			</div>

			<div className={classes.footer}>
				<div>
					<span>Already have an account ? </span>
					<Button className={classes.signin} onClick={() => props.setScreen('Login')}>
						Sign In !{' '}
					</Button>
				</div>
			</div>
		</div>
	);
}

export default withRouter(SignUp);
