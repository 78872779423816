import * as actions from '../actions/organisation.actions.js';

const createOrganisation = function (state = {}, action) {
	switch (action.type) {
		case actions.GET_ORGANISATION:
			if (action.payload) {
				// console.log("action.payload",action.payload);
				return {
					...action.payload
				};
			}
			break;
		// case actions.REMOVE_TERMS:
		// 	if (action.payload) {
		// 		// return {...action.payload};
		// 		return action.payload;
		// 	}
		// 	break;
		case actions.GET_ORGANISATION_ERROR:
			if (action.payload) {
				return {
					...action.payload
				};
			}
			break;
			case actions.ORGANISATION_KYC:
			if (action.payload) {
				return {
					...action.payload
				};
			}
			break;
		case actions.ORGANISATION_KYC_ERROR:
			if (action.payload) {
				return {
					...action.payload
				};
			}
			break;
		case actions.ORGANISATION_NULL:
			if (action.payload) {
				return {
					...action.payload
				};
			}
			break;
		// case actions.CREATE_ORGANISATION:
		// 	if (action.payload) {
		// 		return {
		// 			...action.payload
		// 		};
		// 	}
		// 	break;
		// case actions.CREATE_ORGANISATION_ERROR:
		// 	if (action.payload) {
		// 		return {
		// 			...action.payload
		// 		};
		// 	}
		// 	break;
		// case actions.UPDATE_ORGANISATION:
		// 	if (action.payload) {
		// 		return {
		// 			...action.payload
		// 		};
		// 	}
		// 	break;
		// case actions.UPDATE_ORGANISATION_ERROR:
		// 	if (action.payload) {
		// 		return {
		// 			...action.payload
		// 		};
		// 	}
		// 	break;
		// case actions.DELETE_ORGANISATION:
		// 	if (action.payload) {
		// 		return {
		// 			...action.payload
		// 		};
		// 	}
		// 	break;
		// case actions.DELETE_ORGANISATION_ERROR:
		// 	if (action.payload) {
		// 		return {
		// 			...action.payload
		// 		};
		// 	}
		// 	break;
		default:
			return state;
	}
};

export default createOrganisation;
