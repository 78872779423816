import React from 'react';
import { Dialog, DialogActions, Button } from '@material-ui/core';
// DialogTitle, DialogContent,
function ConfirmDelete(props) {
	// console.log(props,"propsdelete");
	const { confirmDelete, setConfirmDelete } = props;
	return (
		<Dialog open={confirmDelete.openForDelete}>
			<div style={{ minHeight: "150px", minWidth: "350px", padding: "10px", textAlign:"center" }}>
				<p style={{ fontSize: "18px", marginTop:"40px",marginBottom:"8px" }}>{confirmDelete.title} ?</p>
				<p style={{ color: "#000", fontSize: "16px" }}>{confirmDelete.Subtitle} !!!</p>
			</div>
			<DialogActions style={{ padding: "10px 20px 28px", justifyContent: "space-evenly" }}>
				<Button onClick={confirmDelete.onConfirm} className="userDeleteConfirm"
					style={{background: "#be3728"}}>
					Yes
					</Button>
				<Button onClick={() => setConfirmDelete({ ...confirmDelete, openForDelete: false })} className="userDeleteConfirm" style={{background: "#0062FF"}}>
					No
					</Button>
			</DialogActions>
		</Dialog>
	);
}
export default React.memo(ConfirmDelete);
