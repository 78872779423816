/* eslint-disable camelcase */

import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import authService from 'app/services/authService';
import * as LayoutActions from 'app/app-layout/store/layoutSlice/actions';
import OtpInput from 'react-otp-input';
import * as Actions from './store/loginSlice/actions';

const useStyles = makeStyles(theme => ({
	main: {
		fontFamily: 'Nunito',
		height: '100%',
		display: 'flex',
		flex: 1,
		flexDirection: 'column'
	},
	title: { fontSize: 35, color: 'black', textAlign: 'center', flex: 1 },
	icon: { fontSize: 32, color: 'black', flex: 1 },
	subHead: { fontSize: 15, color: '#AFB3B0' },
	subDiv: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
	otpInput: {
		height: 48,
		width: '46px !important',
		border: '2px solid #0062FF',
		borderRadius: 8,
		color: '#0062FF',
		fontSize: 28,
		marginLeft: 15,
		'&:focus': {
			outline: 'none'
		}
	},
	button: {
		width: '40%',
		color: 'white',
		fontSize: 15,
		fontWeight: 'bold',
		padding: 8,
		textTransform: 'none',
		background: '#0062FF',
		marginTop: 30,
		borderRadius: 7,
		'&:hover': {
			background: '#0062FF'
		}
	},
	resend: { color: '#0062FF', fontSize: 16, textTransform: 'none', paddingTop: 0 },
	seconds: { color: '#0062FF', fontSize: 18 },
	footer: { color: 'black', marginBottom: 10, textAlign: 'center' },
	signin: { color: '#0062FF', fontSize: 18, textTransform: 'none', paddingTop: 0 },
	underline: { borderTop: '2px solid #0062FD', width: 40, marginTop: 10, marginBottom: 10 },
	info: {
		marginTop: 30,
		marginBottom: 20,
		color: 'gray',
		fontFamily: 'Nunito',
		fontSize: 16
	}
}));

function OtpScreen(props) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const { username } = props.data;
	const [otp, setOtp] = React.useState('');
	// const [btnDisabled, setBtnDisabled] = useState(true);

	const [loader, setLoader] = React.useState(false);
	const [response, setResponse] = React.useState(null);

	const [seconds, setSeconds] = React.useState(60);

	React.useEffect(() => {
		setTimeout(() => {
			if (seconds > 0) setSeconds(seconds - 1);
		}, 1000);
	}, [seconds]);

	const otpLength = otp.length;
	// console.log(otpLength, 'otpLength');

	const handleClose = () => {
		setOtp('');

		props.setData({});
		props.setScreen('InputScreen');

		props.onClose();
	};

	// const handleVerify = async () => {
	// 	const payload = { phoneNumber: username, otp };
	//
	// 	setLoader(true);
	// 	setResponse(null);
	//
	// 	const res = await dispatch(Actions.VerifyOTP(payload));
	//
	// 	setLoader(false);
	// 	setResponse(res);
	// 	//	console.log(res, 'resssssssssss');
	//
	// 	if (res.status === 200 && res.response.data.kycStatus === false) props.history.push('/profile');
	// 	if (res.status === 200 && res.response.data.access_token) props.history.push('/welcome');
	// };

	const handleVerify = async () => {
		const payload = { phoneNumber: username, otp };

		//props.mixpanel.track('Button Click', { name: 'Verify OTP' });

		const layout = {
			layout: 'private',
			header: true,
			sidebar: false
		};

		setLoader(true);
		setResponse(null);

		const res = await authService.signInWithPhoneNumber(payload);
		await dispatch(Actions.SignIn(res));

		setLoader(false);
		setResponse(res);

		if (res.status === 200) {
			await dispatch(LayoutActions.getLayout(layout));
			await handleClose();

			//console.log(res.response.data.userData.kycStatus, 'res.response.data.data');
			localStorage.setItem('userPhoneNumber', res.response.data.userData.phoneNumber);
			localStorage.setItem('kycResponse', res.response.data.userData.kycStatus);

			if (res.status === 200 && res.response.data.userData.kycStatus === false)
				props.history.push('/profile-reg');
			if (
				res.status === 200 &&
				res.response.data.userData.kycStatus === true &&
				res.response.data.tokenData.access_token
			) {
				// To store organization data in localstorage
				await Actions.setOrganizationInLocalStorage();
				// To store user data in localstorage
				await Actions.setUserInLocalStorage();
				props.history.push('/welcome');
			}
			// props.history.push({ pathname: './welcome' });
		}
	};

	const handleResend = () => {
		const payload = { phoneNumber: username };
		dispatch(Actions.RequestOTP(payload));

		setSeconds(60);
	};

	const errMsg = response?.data?.error_description || 'OTP Failed';

	return (
		<div className={classes.main}>
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<div className={classes.title}>Validate OTP</div>

				<IconButton onClick={() => handleClose()} style={{ padding: 0 }}>
					<ClearRoundedIcon className={classes.icon} />
				</IconButton>
			</div>

			<center>
				<div className={classes.underline} />
			</center>

			<center className={classes.subHead}>Please enter the OTP sent to {username}</center>

			<div className={classes.subDiv}>
				<OtpInput
					value={otp}
					onChange={val => setOtp(val)}
					numInputs={4}
					isInputNum
					inputStyle={classes.otpInput}
					containerStyle={{ marginTop: 20 }}
				/>
				{otpLength === 4 ? (
					<Button variant="contained" className={classes.button} onClick={() => handleVerify()}>
						Verify
					</Button>
				) : (
					<div className={classes.info}>Please Enter 4 digit OTP</div>
				)}

				<div style={{ color: 'black', textAlign: 'center', marginTop: 10 }}>
					<span>Didn't receive an OTP ? </span>
					{seconds ? (
						<span>
							{' '}
							Resent OTP in <span className={classes.seconds}>{seconds}</span> seconds
						</span>
					) : (
						<Button className={classes.resend} onClick={() => handleResend()}>
							Resend
						</Button>
					)}
				</div>

				<div style={{ marginTop: 20, textAlign: 'center', fontSize: 20 }}>
					{response && response.status !== 200 ? <div style={{ color: 'red' }}>{errMsg}</div> : null}
					{loader ? (
						<div style={{ color: '#0062FF', fontSize: 14, fontWeight: 'bold' }}>
							Validating OTP . . . Please Wait
						</div>
					) : null}
				</div>
			</div>

			{/*	<div className={classes.footer}>
				<div>
					<span>Already have an account ? </span>
					<Button className={classes.signin} onClick={() => props.setScreen('Login')}>
						Sign In !{' '}
					</Button>
				</div>
			</div> */}
		</div>
	);
}

export default withRouter(OtpScreen);
