import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import InputBase from '@material-ui/core/InputBase';

// import Hidden from '@material-ui/core/Hidden';
// import CardMedia from '@material-ui/core/CardMedia';
// import Icon from '@material-ui/core/Icon';
// import Auth from 'app/main/auth';
// import Backdrop from '@material-ui/core/Backdrop';

import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';

import MobileImg from '../../../images/mobile_blank.jpg';
import GoogleImg from '../../../images/google-play.svg';

const useStyles = makeStyles(theme => ({
	container: {
		maxWidth: '100%',
		fontFamily: 'Poppins',
		paddingTop: 80,
		paddingBottom: 80
	},
	main: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		[theme.breakpoints.down('md')]: {
			alignItems: 'center'
		}
	},
	mainDiv: {
		paddingLeft: 40,
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 0
		}
	},
	title: { fontSize: 20, fontWeight: 'bold', color: '#1D2238' },
	sub: {
		fontSize: 70,
		color: '#1A6AE9',
		fontWeight: 'bolder',
		[theme.breakpoints.only('sm')]: {
			fontSize: 60
		},
		[theme.breakpoints.only('xs')]: {
			fontSize: 50
		}
	},
	sub2: {
		fontSize: 70,
		color: '#1A6AE9',
		fontWeight: 'bolder',
		lineHeight: 0.7,
		[theme.breakpoints.only('sm')]: {
			fontSize: 60
		},
		[theme.breakpoints.only('xs')]: {
			fontSize: 50
		}
	},
	title2: { fontSize: 25, fontWeight: 'bold', color: '#1D2238' },
	input: {
		width: 150,
		'& ::-webkit-input-placeholder': {
			color: 'black',
			fontSize: 15
		}
	},
	button: {
		background: '#1A6AE9',
		width: 150,
		color: 'white',
		marginLeft: 20,
		textTransform: 'none',
		fontWeight: 'bold'
	},
	play: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: 5,
		marginTop: 25,
		width: 180,
		background: 'black'
	}
}));

function Home(props) {
	const classes = useStyles();

	// const [open, setOpen] = React.useState(false);
	// const [screen, setScreen] = React.useState('');
	// const [phone, setPhone] = React.useState('');

	// const handleClick = val => {
	// 	setScreen(val);
	// 	setOpen(true);
	// };

	return (
		<Container className={classes.container}>
			<Grid container spacing={3}>
				<Grid item xs={12} md={6} className={classes.main}>
					<div className={classes.mainDiv}>
						<div className={classes.title}>India’s fastest LR generating tool</div>

						<div className={classes.sub}>BILTI BANAO</div>
						<div className={classes.sub2}>BEFIKAR</div>

						<div style={{ marginTop: 20 }} />

						<div className={classes.title2}>Helping Transporters generate</div>
						<div className={classes.title2}> LR in less than 30sec</div>

						<div style={{ display: 'flex', marginTop: 20 }}>
							<Card style={{ display: 'flex', alignItems: 'center', padding: 5, width: 150 }}>
								<div style={{ color: 'black', marginRight: 3 }}>+91-</div>
								<InputBase
									className={classes.input}
									placeholder="Enter Phone No."
									inputProps={{ 'aria-label': 'naked' }}
									InputProps={{
										className: classes.input
									}}
								/>
							</Card>

							<Button variant="contained" size="large" className={classes.button} endIcon={<SendIcon />}>
								Get App Link
							</Button>
						</div>

						<Card className={classes.play}>
							<div style={{ display: 'flex' }}>
								<img width="30" height="20" src={GoogleImg} alt="Google_Play" />

								<div style={{ marginLeft: 15 }}>
									<div style={{ color: 'white', fontSize: 12 }}>GET IT ON</div>
									<div style={{ color: 'white', fontSize: 17, fontWeight: 'bold' }}>Google Play</div>
								</div>
							</div>
						</Card>
					</div>
				</Grid>

				<Grid item xs={12} md={6}>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<img src={MobileImg} alt="Mobile" style={{ width: 500 }} />
					</div>
				</Grid>
			</Grid>
		</Container>
	);
}

export default Home;
