import API from 'app/middleware';
import apiCOnfig from 'app/app-configs/apiConfig';
import {
  getUsers,
  createUser,
  updateUser,
  deleteUser,
  uploadUser,
  signedUrlUser,
  userReset,
  uploadReset,
	errorResponse
} from './userSlice';

export const getUsersApi = () => async dispatch => {
  const reqObj = {
		method: 'GET',
		url: apiCOnfig.baseUrl + '/user-ms/api/users/getAllUsers',
	};
	const response = await API(reqObj);
	if (!response) {
		errorResponse(response);
	}
	dispatch(getUsers(response));
}
export const createUserApi = data => async dispatch => {
  const reqObj = {
		method: 'POST',
		url: apiCOnfig.baseUrl + '/user-ms/api/users',
    data
	};
	const response = await API(reqObj);
	if (!response) {
		errorResponse(response);
	}
	dispatch(createUser(response));
}
export const updateUserApi = data => async dispatch => {
  const reqObj = {
		method: 'PUT',
		url: apiCOnfig.baseUrl + '/user-ms/api/users',
    data
	};
	const response = await API(reqObj);
	if (!response) {
		errorResponse(response);
	}
	dispatch(updateUser(response));
}
export const deleteUserApi = data => async dispatch => {
  const reqObj = {
		method: 'DELETE',
		url: apiCOnfig.baseUrl + '/user-ms/api/users',
    data
	};
	const response = await API(reqObj);
	if (!response) {
		errorResponse(response);
	}
	dispatch(deleteUser(response));
}
export const uploadUserApi = (file, id, docType) => async dispatch => {
	const reqObj = {
		method: 'POST',
		url: apiCOnfig.baseUrl + '/user-ms/api/user/upload/' + id + '/' + docType,
		data:file
	};
	const response = await API(reqObj);
	if (!response) {
		errorResponse(response);
	}
	dispatch(uploadUser(response));
};
export const getSignedUrlForUsersApi = data => async dispatch => {
  const reqObj = {
		method: 'POST',
		url: apiCOnfig.baseUrl + '/aws-ms/api/s3/get-signed-url',
    data
	};
	const response = await API(reqObj);
	if (!response) {
		errorResponse(response);
	}
	dispatch(signedUrlUser(response));
}
export const userResetApi = data => async dispatch => {
  const response = {};
  await dispatch(userReset(response));
}

export const imageResetApi = data => async dispatch => {
  const response = {};
  await dispatch(uploadReset(response));
}
