import Welcome from './Welcome';
import WelcomeSetup from './WelcomeSetup';

const WelcomeConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/welcome',
			component: Welcome,
			isPrivate: true,
		},
		{
			path: '/welcome_setup',
			component: WelcomeSetup,
			isPrivate: true,
		},
		{
			path: '/private',
			component: Welcome,
			isPrivate: true,
		},
		{
			path: '/private-auth',
			component: Welcome,
			isPrivate: true,
			auth: ['admin','superadmin'],
		}
	]
};

export default WelcomeConfig;

/**
 * Lazy load Welcome
 */

/*
import React from 'react';

const WelcomeConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/welcome',
            component: React.lazy(() => import('./Welcome'))
        }
    ]
};

export default WelcomeConfig;

*/
