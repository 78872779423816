import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
	name: 'usersStore',
	initialState: {
		error: {}
	},
	reducers: {
		getUsers: (state, action) => {
			state.users = action.payload.response;
		},
		createUser: (state, action) => {
			state.user = action.payload.response;
		},
		updateUser: (state, action) => {
			state.user = action.payload.response;
		},
		deleteUser: (state, action) => {
			state.user = action.payload.response;
		},
		uploadUser: (state, action) => {
			state.userUpload = action.payload.response;
		},
		signedUrlUser: (state, action) => {
			state.userSignedUrl = action.payload.response;
		},
		userReset:(state,action) => {
			state.user = action.payload.response
		},
		uploadReset:(state,action) => {
			state.userUpload = action.payload.response
		},
		errorResponse: (state, action) => {
			state.error = action.payload;
		}
	}
});

export default userSlice.reducer;

export const {
	getUsers,
	createUser,
	updateUser,
	deleteUser,
	uploadUser,
	signedUrlUser,
	userReset,
	uploadReset,
	errorResponse
} = userSlice.actions;
